import React, { useEffect, useState } from 'react';
import RenderAttr from './RenderAttr';
import MapColumnModal from '../../../Modals/MapColumn';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { toastify } from '../../../../common-functions/notify';

function MapColumns({csvData, finalData, importType, pimFields: fields, setData, selectedMp}){
    const [closeStatus, setCloseStatus] = useState([])
    const [pimFields, setPimFields] = useState({})
    const [fileFields, setFileFields] = useState([])
    const [modalShow, setModalDisplay] = useState(false)
    const [stores, setStores] = useState([])
    const [selectedStores, setSelectedStores] = useState({})
    const [droppedField, setDroppedField] = useState('')
    const [selectedColumns, setSelectedColumns] = useState(new Map())
    const [selectedMpColumns, setSelectedMpColumns] = useState(new Map())
    const [chosenMarketplace, setMp] = useState('')

    useEffect(() => {
        let arr = csvData
        if(arr){
            setFileFields(arr[0]) 
            let tempArr = new Array(arr[0].length)       
            for(let i=0; i < arr[0].length; i++){
                tempArr[i] = false
            }
            setCloseStatus(tempArr)
        }
        setPimFields(fields)
    },[fields])

    const hideModal =()=> {
        setModalDisplay(false)
    }

    const dragStart = (e, field, marketplace) => {
        e.dataTransfer.setData("Text", e.target.id)
        marketplace && setStoreArr(marketplace) 
        marketplace ? setMp(marketplace) : setMp('')
        setDroppedField(field)
    }

    const setStoreArr = (mp) => {
        let arr = []
        let stores = pimFields['Marketplaces'][mp]
        for(let i=0; i < stores.length; i++){
            arr.push({
                store : stores[i],
                checked: false
            })
        }
        setStores([...arr])
    }

    const onStoreSelect = (index) => {
        let arr = [...stores]
        arr[index]['checked'] = !arr[index]['checked']
        setStores([...arr])

        //set Selected stores
        let newArr = []
        for(let i=0; i < arr.length; i++){
            if(arr[i].checked)
                newArr.push(arr[i].store)
        }
        let obj = {...selectedStores}
        obj[chosenMarketplace] = [...newArr]
        setSelectedStores(obj)
    }

    const drop = (e, field) => {
        e.preventDefault()
        let data = e.dataTransfer.getData("Text")
        let nodeCopy = document.getElementById(data).cloneNode(true)
        nodeCopy.id = field;
        e.target.appendChild(nodeCopy)
        
        let index = fileFields.indexOf(field)
        let arr = [...closeStatus]
        arr[index] = true
        setCloseStatus(arr)

        selectedStores[chosenMarketplace] ? setModalDisplay(false) : setModalDisplay(true)
        mapCols(field)
    }

    const allowDrop = (e) => {
        e.preventDefault()
    }

    const removeField = (field, key) => {
        if(chosenMarketplace){
            console.log('hi')
        }
        else{
            let temp_map = selectedColumns
            let newKey = temp_map.get(field)
            temp_map.set(newKey, field)
            temp_map.delete(field)           
            setSelectedColumns(temp_map)

            let id = 'droptarget-'+key
            let element = document.getElementById(id)
            element.removeChild(document.getElementById(field))
            let index = fileFields.indexOf(field)
            let arr = [...closeStatus]
            arr[index] = false
            setCloseStatus(arr)
        }
    }

    const mapCols = (field) => {
        if(chosenMarketplace){
            mapMpCols(field)
        }
        else{
            mapOtherCols(field)
        }
    }

    const mapOtherCols = (field) => {
        let temp_map = selectedColumns
        temp_map.set(field, droppedField)
        
        setSelectedColumns(temp_map)
    }

    const mapMpCols = (field) => {
        const temp_map = selectedMpColumns
        let map = new Map()
        if(selectedMpColumns.has(chosenMarketplace)){
            map = selectedMpColumns.get(chosenMarketplace)
        }
        map.set(field, droppedField)
        temp_map.set(chosenMarketplace, map)
        setSelectedMpColumns(temp_map)
    }

    const saveCol = () => {
        if(selectedColumns.length === 0)
            toastify('error', `You have not mapped any column.`)
        else{
            toastify('success', `Saved`)
            mapCSVFields()
        }
    }

    const mapCSVFields = () => {
        let fData = {...finalData}
        let dataArr = fData['data']
        let marketplaces = {}

        // for(let [key, value] of Object.entries(selectedStores)){
        //     for(let i=0; i < value.length; i++){
        //         marketplaces[value[i]] = []
        //     }
        // }

        if(selectedColumns.size > 0){
            for(let i =0; i < dataArr.length; i++){
                let new_obj = {...dataArr[i]}
                for(let[key, value] of selectedColumns.entries()){ 
                    if(dataArr[i][key] & dataArr[i][key] === value){
                        new_obj[value] = dataArr[i][key]                    
                        new_obj['key'] = dataArr[i].key
                        delete new_obj[key]
                    }
                }
                dataArr[i] = {...new_obj}
            }
        }

        if(selectedMpColumns.size > 0){
            for(let [key, value] of selectedMpColumns.entries()){
                 marketplaces[key] = []
                 for(let i=0; i < dataArr.length; i++){
                     let newObj = {}
                     for(let [key2, value2] of Object.entries(dataArr[i])){
                         if(value.has(key2)){
                                newObj[value.get(key2)] = value2
                                delete dataArr[i][key2]
                         }
                     }
                     newObj['key'] = dataArr[i].key
                     marketplaces[key].push(newObj)
                 }
            }
            
        } 
        
        fData['marketplaces'] = {...marketplaces}
        fData['data'] = [...dataArr]
        console.log(fData)
        // setData(fData)
    }

    return(
        <div className="container-fluid map-columns">
            <Button className="save-mp" onClick={saveCol}>Save</Button>
            <div className="row">
                <div className="col-12 col-lg-6 map-columns_left">
                    <div className="container-fluid pim-columns">
                        <div className="row col-header">
                            <h5>Pim Fields</h5>
                        </div>
                        <div className="row col-body">
                            <RenderAttr 
                                dragStart={dragStart}
                                importType={importType}
                                pimFields={pimFields} 
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 map-columns_right">
                    <div className="container-fluid file-columns">
                        <div className="row col-header">
                            <h5>Mapping Fields</h5>
                        </div>
                        <div className="row col-body">
                            <div className="sub-heading d-flex">
                                <div className="flex-fill">
                                    Selected Grid
                                </div>
                                <div className="flex-fill">
                                    Col Index
                                </div>
                                <div className="flex-fill">
                                    Label
                                </div>
                            </div>
                            
                            {fileFields.map((field, key)=>{
                                return(
                                    <div className="col-content d-flex" key={key}>
                                        <div 
                                            className="flex-fill droptarget"  
                                            onDrop={(e)=>drop(e, field)} 
                                            onDragOver={(e)=>allowDrop(e)}
                                            id={`droptarget-${key}`}
                                        >
                                            <FontAwesomeIcon
                                                className={`close-icon ${closeStatus[key]}`}
                                                icon={faTimes}
                                                onClick={() =>removeField(field, key)}
                                            />
                                        </div>
                                        <div className="flex-fill">
                                            {key}
                                        </div>
                                        <div className="flex-fill">
                                            {field}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <MapColumnModal 
                modalShow={modalShow} 
                onHide={hideModal} 
                stores={stores}
                onCheckboxClick={onStoreSelect}
            />
        </div>
    );
}

export default MapColumns;