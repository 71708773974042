import React, { Component } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import Main from "./MainComponent";
import ProtectedRoute from "./common/ProtectedRoute";
import FrontPage from "./Login-Register/FrontPage";

class Router extends Component {
  state = {};
  render() {
    const NotFound = () => <h1>404.. This page is not found!</h1>;
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/not-found' component={NotFound} />
          <Route path='/register' component={FrontPage} />
          <Route path='/login' component={FrontPage} />
          <Route path='/shopify' component={FrontPage} />
          <ProtectedRoute path='/:home/:module/:type/:category/:sku' component={Main} />
          <ProtectedRoute path='/:home/:module/:type/:category' component={Main} />
          <ProtectedRoute path='/:home/:module/:type' component={Main} />
          <ProtectedRoute path='/:home/:module' component={Main} />
          <ProtectedRoute path='/:home' component={Main} />
          <ProtectedRoute path='/' exact component={Main} />
          {/* <Route path='*' component={NotFound} /> */}
          <Redirect to='/login' />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
