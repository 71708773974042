import React from 'react';
import _ from "lodash";

function TableBody({columns, data}) {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item)

    return _.get(item, column)
  }

  const createKey = (item, column) => {
    return item.Sku + column
  }

  return (
    <tbody>
        {data.map(item => (
          <tr key={item._id}>
            {columns.map(column => (
              <td key={createKey(item, column)}>
                {renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
    </tbody>
  );
}

export default TableBody;