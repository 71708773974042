import React, { useEffect, useState } from 'react';

import { createProducts, getStores} from '../../../services/mappingPortalService';
import CsvPreview from '../common/CsvPreview';
import CSVUpload from './CSVUpload';
import { getUser } from '../../../services/authService';
import LastImport from '../LastImport';
import NavigationBar from '../common/NavigationBar';
import { toastify } from '../../../common-functions/notify';
import LoadingAnimation from '../../common/LoadingAnimation';

const categories = ['Upload', 'Preview CSV', 'Finish']

function CreatePortal({companyKey, csvData, setCsvData}) {
  const [stores, setStores] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('Upload')
  const [response, setResponse] = useState([])
  const [disableImportBtn, setDisableImportBtn] = useState(false)

  const getStoresList= async () => {
    const formData = {
        'companyKey' : companyKey
    }
    return await getStores(formData)
  }

  useEffect(() => {
    getStoresList().then(res=> {
      let temp= res.result.reduce((arr, item)=> {
        arr.push({
          name: item,
          checked: false
        })
        return arr
      },[])
      setStores(temp)
    })
  }, [companyKey])

  const onStoreSelect = (e, store) => {
    let tempArr = [...stores]
    let temp = tempArr.filter(item=> item.name === store)
    tempArr[tempArr.indexOf(temp[0])].checked = e.target.checked
    setStores(tempArr)
  }

  const handleNext =()=> {
      setSelectedCategory(categories[categories.indexOf(selectedCategory)+1])
  }

  const handleImport = async ()=> { 
    setDisableImportBtn(true)  
    const data = [...csvData]
    let keys = data[0]
    // if(areKeysValid(data[0])){
      let finalData = data.map(row => {
        return keys.reduce((object, curr, i) => (object[curr] = row[i], object), {})
      })
      const formData = {
        "companyKey": companyKey,
        "email": getUser().userEmail,
        "data": finalData.slice(1)
      }
      try{
        let res = await createProducts(formData)
        if(res.result === 'success'){
          toastify('success', "Request sent", "Importing will begin soon.")
          window.location='/mappingPortal'
        }
        
        console.log(res)
      }
      catch(ex){
        toastify('error', "Server Error", "Please try again later.")
      }
    // }
    // else{
    //   toastify('error', "Error", "Please provide valid data.")
    // }
  }

  const areKeysValid = (arr) => {
    if(arr.length > 2 && arr[0] === 'Base' && arr[1] === 'Variant'){
      let tempStores = arr.slice(2)
      for(let i=0; i < tempStores.length; i++){
        if(!stores.includes(tempStores[i]))
          return false
      }
      return true
    }
    else 
      return false
      
  }

  const renderNext = () => {
    if(selectedCategory === 'Upload')
      return (
        <CSVUpload 
          next={handleNext}
          onStoreSelect={onStoreSelect}
          setCsvData={setCsvData}
          stores={stores}
        />
      )
    else if(selectedCategory === 'Preview CSV')
      return(<CsvPreview key={companyKey} csvData={csvData}/>)
    
    else if(selectedCategory === 'Finish')
      return(
        <LastImport key={companyKey} response={response} />
      )
    else 
      return null
  }

  return (
    <div className="container-fluid create-portal">
      {disableImportBtn ? <LoadingAnimation /> : null}
       <div className="row">
          <NavigationBar categories={categories} selectedCategory={selectedCategory}/>
       </div>
       <div className="row">
          {selectedCategory === 'Preview CSV' ?
            <button className="portal-btn" onClick={handleImport}>Import</button> 
            :null}
       </div>
       <div className="row mt-5 justify-content-center">
          {renderNext()}
       </div>
    </div>
  );
}

export default CreatePortal;