import React from 'react';
import ReactTooltip from 'react-tooltip';

function StockOutProducts({stockCount, _id}) {
  const setUI = () => {
      let data = getStockOutProducts()
      let variants = [...data.keys()]
      return(
        variants.map((item, key) => {
          return(
            <div className="broken_count-div" key={key}>
            <h6>{item}</h6>
            <div className="d-flex flex-wrap">
              {data.get(item).map((inv, keyII)=>{              
                  return(
                    <div className="p-1" key={keyII}>
                      <div className={`count-div zero`}>
                          <div className="label">{inv}</div>  
                          <div className="cross"></div>                  
                      </div>
                    </div>
                  )
              })}
            </div>
          </div>
          )
        })
      )
  }

  const getStockOutProducts = () => {
    let map = new Map()
    for(let [key, value] of Object.entries(stockCount)){
      for(let [key2, val2] of Object.entries(value)){
        if(val2 === 0){
          if(map.has(key))
            map.get(key).push(key2)
          else{
            map.set(key, [key2])
          }
        }
      }
    }
    return map
  }

  if(!stockCount)
    return null

  return (
    <ReactTooltip id={'broken_'+_id} aria-haspopup='true' backgroundColor='#f5f5f5' clickable={true}>
			  <div className="broken_tooltip-div">
            <h5>Stock out Products</h5>
            {setUI()}
				</div>
		</ReactTooltip>
  );
}

export default StockOutProducts;