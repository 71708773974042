import React from "react";
import img from '../../assets/full-screen.png'
import refresh from '../../assets/clear-cache.png';
import axios from 'axios';

const Header = ({ title, secondaryText }) => {

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } 
    else {
      if (document.exitFullscreen) {
          document.exitFullscreen();
      }
    }
  }

  const clearCache = () => {
    axios({
      method: 'get',
      url: 'https://py.lyzer.io/clearCache',
      headers: { 
          'Accept': 'application/json',
          'content-type': 'application/json'
      },
    }).then(res => {
      console.log(res);
      window.location.reload()
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <div className='row lyzer-header'>
      <div className='col-5 col-lg-3'>
        <span className='heading'>{title}</span>
        {secondaryText && (
          <span className='secondary-heading'> {secondaryText}</span>
        )}
      </div>
      {/* <div className */}
      <img src={img} className="fullscreen" style={{ cursor: 'pointer' }} onClick={() => toggleFullScreen()} />
      <img src={refresh} className="refresh" title="clear cache" style={{ cursor: 'pointer' }} onClick={() => clearCache()} />
    </div>
  );
};

export default Header;
