import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { ExcelRenderer } from 'react-excel-renderer';

import Dropzone from '../../UI Elements/Dropzone';
import { setCsvName } from '../../../services/mappingPortalService';

function UploadFile({next, setCsvData, templateData, templateTypes}) {
    const [selectedTemplate, setSelectedTemplate] = useState(templateTypes[0])

    const onFileDrop =(data)=>{
        setCsvName(data.name)
    
        ExcelRenderer(data, (err, resp) => {
            setCsvData(resp['rows'])
            next()
        })
    }
       
    const onFileSelect = (event) => {
        // console.log(data)
        setCsvName(event.target.files[0].name)
        
        ExcelRenderer(event.target.files[0], (err, resp) => {
            setCsvData(resp['rows'])
            next()
        })
    }

    const setTemplate = (e) => {
        setSelectedTemplate(e.target.value)
    }

    if(!templateData)
        return null
    return (
        <div className="container-fluid" align="center">
            <div className="upper row">
                <div className="col-12 col-md-4 offset-md-3">
                    <select name='importType' value={selectedTemplate} className="form-control"  onChange={setTemplate}>
                        {templateTypes.map((option, key) => (
                            <option key={key} value={option} >
                                {option.label}
                            </option>
                        ))}
                    </select>

                </div>
                <div className="col-12 col-md-4">
                    <CSVLink data={templateData.get(selectedTemplate.value)} filename={'' + selectedTemplate + '-template.csv'} className='btn get-template'>
                        Get Template
                    </CSVLink>
                </div>
            </div>
            <br />
            <br />
            <br />
            <span style={{ opacity: 0.4 }}>------------------------------ Or Upload file directly ------------------------------</span>
            <div className="lower">
                <div className="container-fluid importProducts">
                    <Dropzone onFileDrop={onFileDrop} onFileSelect={onFileSelect} />
                </div>
            </div>
        </div>
    );
}

export default UploadFile;