import React, { useState, useEffect } from "react";
import Cards from "../Graphs/Cards/Cards";
import GraphTabs from "../Graphs/BarGraphs/GraphTabs";
import DoughnutChart from "./graphicalComponent/Doughnut";
import ProductWidget from "./graphicalComponent/ProductWidget";
import ConversionWidget from "./graphicalComponent/ConversionWidget";
import MapWidget from "./graphicalComponent/MapWidget";
import ErrorPage from "../common/ErrorPage";
import Header from "../common/Header";
import {
  cancelRequest,
  getAnalyticsData,
} from "../../services/dashboardService";

function Dashboard({ companyInfo, companyKey, fromDate, toDate }) {
  const currencySymbol = companyInfo ? companyInfo["Currency Symbol"] : "$";
  const timeZone = companyInfo ? companyInfo["Time Zone"] : "";

  const [loading, setLoading] = useState(true);
  const [loadingLineGraph, setloadingLineGraph] = useState(true);
  const [error, setError] = useState(false);

  const [lineData, setLineGraph] = useState({});
  const [graph, setLineGraphType] = useState({});

  const [doughnutData, setDoughnutGraphData] = useState({});
  const [cardsData, setCardsData] = useState({});
  const [mapData, setMapData] = useState({});
  const [googleData, setGoogleData] = useState({});

  const [topSellingProducts, setProducts] = useState([]);
  const [topTrendingProducts, setTTProducts] = useState([]);
  const [biggestLosers, setBLProducts] = useState([]);
  const [slowestMoving, setSMProducts] = useState([]);

  const [dateType, setDateType] = useState("date");
  const [dateArray, setDateArray] = useState([]);

  const cardsColor = [
    {
      color: "#e91e63",
      background: "#f0dbe2",
    },
    {
      color: "#7767c1",
      background: "#ece9f9",
    },
    {
      color: "#03a9f4",
      background: "#e0effb",
    },
    {
      color: "#ff6054",
      background: "#fbdad8",
    },
    {
      color: "#ff9800",
      background: "#f9edda",
    },
    {
      color: "#80e5b8",
      background: "#d4fdeb",
    },
  ];

  useEffect(() => {
    if (fromDate === toDate) {
      setDateType("hour");
      sendAxiosRequest(fromDate, toDate, "hour");
    }
    setLoading(true);
    setloadingLineGraph(true);
    sendAxiosRequest(fromDate, toDate, dateType);

    return () => {
      cancelRequest();
    };
  }, [fromDate, toDate, companyKey]);

  const setDateTypeFn = (childData) => {
    setloadingLineGraph(true);
    setDateType(childData);
    sendAxiosRequest(fromDate, toDate, childData);
  };

  const sendAxiosRequest = async (from, to, timePeriod) => {
    const formData = {
      fromDate: from,
      toDate: to,
      companyKey: companyKey,
      timeZone: timeZone,
      step: timePeriod === "" ? "week" : timePeriod,
      chartTypes: {
        PIE: {
          PIE_MARKETPLACE_ORDERS: {},
          PIE_MARKETPLACE_ORDER_SALES: {},
          PIE_MARKETPLACE_RETURN_SALES: {},
          PIE_MARKETPLACE_RETURNS: {},
        },
        BAR: {
          BAR_ORDERS_DAYWISE: {},
        },
        LINE: {
          LINE_ORDERS: {},
          LINE_ORDER_SALES: {},
          LINE_RETURN_ORDERS: {},
          LINE_RETURN_ORDER_SALES: {},
        },
        WHEEL: {
          Brands: {},
          "Product Categories": {},
          "Product Types": {},
          "MarketPlace Sales": {},
        },
        CARD: [
          "CARD_PRODUCTS_COUNT",
          "CARD_VARIANTS_COUNT",
          "CARD_NET_PROFIT",
          "CARD_PROFIT_MARGIN",
          "CARD_TOTAL_SALES",
          "CARD_AVG_SELLING_PRICE",
          "CARD_AVG_REVENUE_PERDAY",
          "CARD_TOTAL_ORDERS",
          "CARD_NET_ORDERS",
          "CARD_PREV_TOTAL_ORDERS",
          "CARD_PREV_NET_PROFIT",
          "CARD_PREV_PROFIT_MARGIN",
          "CARD_PREV_TOTAL_SALES",
          "CARD_PREV_AVG_SELLING_PRICE",
          "CARD_PREV_AVG_REVENUE_PERDAY",
          "CARD_NET_SALES",
          "CARD_TOTAL_RETURN_SALES",
          "CARD_TOTAL_RETURN",
        ],
        LIST: [
          "LIST_ORDERS_STATEWISE",
          "LIST_TOP_SKUS",
          "LIST_TOP_TRENDING",
          "LIST_BIGGEST_LOSER",
          "LIST_SLOWEST_MOVING",
          "LIST_GOOGLE_ANALYTICS",
        ],
      },
    };
    console.log(JSON.stringify(formData));

    try {
      const response = await getAnalyticsData(formData);
      console.log(response.data);

      //set cards data
      setCardsData(response.data["CARD"]);
      setLineGraphType(response.data["CARD"]["CARD_TOTAL_SALES"]);

      //set line graph data
      setLineData(response.data["LINE"]);

      //set date array
      setDateArray([...response.data["LINE"]["LINE_ORDERS"]["steps"]]);

      // set doughnut data
      setDoughnutGraphData(response.data["WHEEL"]);

      //set products list
      setProducts(response.data["LIST"]["LIST_TOP_SKUS"]);
      setTTProducts(response.data["LIST"]["LIST_TOP_TRENDING"]);
      setBLProducts(response.data["LIST"]["LIST_BIGGEST_LOSER"]);
      setSMProducts(response.data["LIST"]["LIST_SLOWEST_MOVING"]);
      setGoogleData(response.data["LIST"]["LIST_GOOGLE_ANALYTICS"]);

      //set Map data
      setMapData(response.data["LIST"]["LIST_ORDERS_STATEWISE"]);

      setLoading(false);
      setloadingLineGraph(false);
    } catch (ex) {
      console.log(ex);
      if (!ex.message === "operation cancelled") setError(true);
    }
  };
  const setLineData = (data) => {
    setLineGraph(data);
  };
  const openGraph = (graph) => {
    setLineGraphType(graph);
  };

  if (error === true) return <ErrorPage errorType={"server"} />;
  else
    return (
      <div className="container-fluid dashboard">
        <Header title="Dashboard" secondaryText={""} />
        <div className="row dashboard_body">
          <div className="container-fluid">
            <div className="row row1 pl-2">
              <Cards
                data={cardsData}
                lineData={lineData}
                loadingDS={loading}
                currencySymbol={currencySymbol}
                openGraph={openGraph}
              />
            </div>

            {/* Bar graphs & doughnut chart */}
            <div className="row row2 p-2">
              <div className="col-11 col-lg-8 mt-3 row2_1">
                <div className="line-graphs">
                  <GraphTabs
                    setDateType={setDateTypeFn}
                    data={lineData}
                    dateType={dateType}
                    dateArray={dateArray}
                    fromDate={fromDate}
                    toDate={toDate}
                    currencySymbol={currencySymbol}
                    graph={graph}
                    loading={loadingLineGraph}
                    companyKey={companyKey}
                  />
                </div>
              </div>
              <div className="col-11 col-lg-4 mt-3 row2_2">
                <div className="doughnut-chartt">
                  <DoughnutChart
                    data={doughnutData}
                    colors={cardsColor}
                    currencySymbol={currencySymbol}
                    loading={loading}
                  />
                </div>
              </div>
            </div>

            {/* Product listing  */}
            <div className="row row3 mt-3 p-2">
              <div className="col-11 col-lg-3 row3_1">
                <div className={`product-widget  ${loading ? "active" : ""}`}>
                  <ProductWidget
                    products={topSellingProducts}
                    title={"Top Selling Products"}
                    currencySymbol={currencySymbol}
                    loading={loading}
                  />
                </div>
              </div>
              <div className="col-11 col-lg-3 row3_2">
                <div className={`product-widget  ${loading ? "active" : ""}`}>
                  <ProductWidget
                    products={topTrendingProducts}
                    title={"Top Trending Products"}
                    currencySymbol={currencySymbol}
                    loading={loading}
                  />
                </div>
              </div>
              <div className="col-11 col-lg-3 row3_3">
                <div className={`product-widget  ${loading ? "active" : ""}`}>
                  <ProductWidget
                    products={biggestLosers}
                    title={"Biggest Losers"}
                    currencySymbol={currencySymbol}
                    loading={loading}
                  />
                </div>
              </div>
              <div className="col-11 col-lg-3 row3_4">
                <div className={`product-widget  ${loading ? "active" : ""}`}>
                  <ProductWidget
                    products={slowestMoving}
                    title={"Slowest Moving Products"}
                    currencySymbol={currencySymbol}
                    loading={loading}
                  />
                </div>
              </div>
            </div>

            {/* <div className="row row5 mt-3 p-2">
              <div className="col-11 col-md-12">
                <div className="conversion-widget">
                  <ConversionWidget
                    currencySymbol={currencySymbol}
                    data={googleData}
                    loading={loading}
                  />
                </div>
              </div>
            </div> */}

            {/* Map chart and conversion info */}
            <div className="row row4 mt-3 p-2">
              <div className="col-11 col-md-12 row4_1">
                <div className="map-widget">
                  {/* {!loading && */}
                  <MapWidget
                    currencySymbol={currencySymbol}
                    data={mapData}
                    loading={loading}
                  />
                  {/* } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Dashboard;
