import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button } from 'reactstrap';
import Image from 'react-bootstrap/Image';

function AddMarketPlace(props) {
    return (
        <Modal show={props.modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add MarketPlace 
                </Modal.Title>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                        <div className="row">
                        {props.marketPlaces.map((item,key)=>{
                            if(props.unselectedMarketPlaces.includes(item.name))
                                return(
                                    <div className="col-12 p-5 col-lg-6"
                                        onClick={props.openDiv(item.name)}
                                        key={key}
                                        >
                                            <div className="container-fluid mp-widget p-3">
                                                <div className="row p-2">
                                                    <div className="col-12 col-lg-8 offset-lg-2 p-2">
                                                        <Image src={require('../../assets/'+ item.image)} />
                                                    </div>
                                                    
                                                </div>
                                                <div className="row p-2 ml-1">
                                                    <h6>Add your product and its variants to {item.name}.</h6>
                                                </div>
                                            </div> 
                                    </div>
                                )
                        })}
                        </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddMarketPlace;