import React, {useState, useEffect} from 'react';
import Pagination from '../../../common/Pagination';
import Image from 'react-bootstrap/Image';
import { paginate } from '../../../../common-functions/paginate';
import { setNumbers } from '../../../../common-functions/setNumbers';
import { CSVLink } from "react-csv";
import _ from "lodash";
import axios from 'axios';
import swal from "sweetalert";

const columns = [
    {
        path : 'sku', label: 'SKU'
    },
    {
        path : 'color', label: 'COLOR'
    },
    {
        path : 'size', label: 'SIZE'
    },
    {
        path : 'inventory', label: 'INVENTORY'
    },
    {
        path : 'orderQty', label: 'ORDERED QTY'
    },
    {
        path : 'price', label: 'PRICE'
    },
    {
        path : 'ros', label: 'ROS'
    },
    {
        path : 'doi', label: 'DOI'
    }
]

function Variants({variants, currencySymbol, mainImage, loadingStatus, companyKey }) {
    const limit = 5
  
    const [searchedValue, setSearchValue] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [variantCsvData, setCsvData] = useState([])
    const [sortColumn, setSortColumn] = useState({ path: "ros", order: "desc" })

    useEffect(() => { 
        console.log(variants)
        setDataInCsv()
    }, [variants, loadingStatus])

    const getPagedData = () => {
        let filtered = []
        if(searchedValue){
            filtered = variants.filter(item => item['sku'].toLowerCase().includes(searchedValue.toLowerCase()) || (item['color'] && item['color'].toLowerCase().includes(searchedValue.toLowerCase())) 
            ||  (item['size'] && item['size'].toLowerCase().includes(searchedValue.toLowerCase())))       
        }
        else
            filtered = [...variants]

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
        const arr = paginate(sorted, currentPage, limit); 
        return { totalCount: filtered.length, data: arr };
    }

    const setDataInCsv = () => {
        if(!loadingStatus){
            let csvHeader = Object.keys(variants[0])
            let csvRows = [csvHeader]  

            for(let i=0; i < variants.length; i++){
                let temp_arr = []
                for(let j=0; j < csvHeader.length; j++){
                    temp_arr.push(variants[i][csvHeader[j]])             
                }
                csvRows.push(temp_arr)
            }
            setCsvData(csvRows)
        }
    }
  
    const search = (event) => {
        const {value} = event.target;
        setSearchValue(value);
    }
    const changePage = (pageNo) => {
      setCurrentPage(pageNo)
    }

    const raiseSort = (path) => {
        const tempSortColumns = { ...sortColumn}
        if (tempSortColumns.path === path)
            tempSortColumns.order = tempSortColumns.order === "asc" ? "desc" : "asc"
        else {
            tempSortColumns.path = path
            tempSortColumns.order = "asc"
        }
        setSortColumn(tempSortColumns)
    }

    const renderSortIcon = (column) => {
        if (column.path !== sortColumn.path) 
            return null
        if (sortColumn.order === "asc") 
            return <i className="fa fa-sort-asc" />;
        return <i className="fa fa-sort-desc" />;
    }

    const { totalCount, data : arr} = getPagedData();

    const handleArchive = (id, archiveValue) => {
        let archiveData = archiveValue === true ? "unarchive" : "archive";
        const formData = {
            "companyKey" : companyKey,
            "sku" : id,
            "type" : archiveData
        }
        console.log("Form Data: " + JSON.stringify(formData));
        axios({
            method: 'post',
            url: 'https://www.razorprime.com/api/actions/archive',
            headers: { 
                'Accept': 'application/json',
                'content-type': 'application/json'
            },
            data: formData
        }).then(res => {
            archiveValue === true ? swal("Good job!", "Product Unarchive", "success").then(function(){window.location.reload()}) : 
            swal("Good job!", "Product Archive", "success").then(function(){window.location.reload()});
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <div className="product-variants p-3">
            <div className="row">
                <div className="col-6 col-md-3 col-lg-3">
                    <h5>Variants</h5>
                </div>
                <div className="col-6 col-lg-4">
                    <div className="form-group has-search">
                        <span className="fa fa-search form-control-feedback"></span>
                        <input className="form-control searchbar" type="text" placeholder="Search" name="searchedValue" value={searchedValue} aria-label="Search" onChange={search}/>
                    </div>
                </div>
                <div className="col-12 col-md-1 export">
                    {!loadingStatus && 
                        <CSVLink data={variantCsvData} filename={"variants.csv"}>
                            <i className='fa fa-download p-1' aria-hidden='true' />
                            Export
                        </CSVLink>
                    }
                </div>
                <div className="col-12 col-md-5 col-lg-4">
                    <Pagination 
                        count={totalCount} 
                        currentPage={currentPage} 
                        changePage={changePage}
                        pageSize={limit}
                    />
                </div>
            </div>
            {/* Variants List  */}
            <div className="wrapped-div-variants">
                <div className="row mt-3 table-header">
                    {columns.map((item, key) => {
                        return(
                            <div className="table_col" onClick={() =>raiseSort(item.path)}>
                                <h6> {item.label} {renderSortIcon(item)}</h6>
                            </div>
                        )
                    })}
                </div>
                {arr.length === 0 ? <p className="not-available-msg">No results found</p> : null}
                {arr.map((item, key) => {
                    return(
                        <div className="row listing-row" key={key}>
                            <div className="table_col">
                                <div className="d-flex justify-content-around">
                                    <div className="p-1 flex-fill">
                                        {item.imgUrl ? <Image src={item.imgUrl}/> :
                                            <Image src={mainImage}/>
                                        }
                                    </div>
                                    <div className="p-1 mt-2 flex-fill sku">
                                        {item.sku.toUpperCase()}
                                    </div>
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {item.color}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {item.size}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {item.inventory}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {item.orderQty}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {currencySymbol}{setNumbers(item.price)}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {(item.ros).toFixed(3)}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    {setNumbers(item.doi)}
                                </div>
                            </div>
                            <div className="table_col">
                                <div className="p-1 mt-2">
                                    <td className="order-edit-cell cell">
                                        <span
                                            className={`order-status ${item.archived === true ? "open" : "closed"}`}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleArchive(item.sku, item.archived) }
                                        >
                                            {item.archived === true ? "Unarchive" : "Archive"}
                                        </span>
                                    </td>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default Variants;