import React from "react";
import Products from "./Products/Products";
import Analytics from "./Analytics/Analytics";
import ProductEdit from "./Products/Pim/ProductEditComponent";
import Orders from "./Orders/Orders";
import ProductEditable from "./Products/Pim/BulkEditor/ProductEditable";
import OrderDetails from "./Orders/OrderDetails/OrderDetails";
import ProductMainPage from "./Products/Analytics/ProductDetails/ProductMainPage";
import Dashboard from "./Dashboard/Dashboard";
import Settings from "./Settings/Settings";
import SettingsAccount from "./Settings/SettingsAccount";
import SettingsUsers from "./Settings/SettingsUsers";
import ResetPassword from "./Settings/ResetPassword";
import MappingPortal from "./Mapping-Portal/MappingPortal";
import NotificationContainer from "./common/Notifications/NotificationContainer";
import ReportContainer from "./Analytics/Reports/View Report Container/ReportContainer";

function RenderedComponent({ companyInfo, companyKey, match, history, notify, fromDate, toDate, onNotificationDataRecieved, report }) {
  if (match.params.home === "dashboard")
    return <Dashboard 
              companyKey={companyKey} 
              companyInfo={companyInfo} 
              fromDate={fromDate}
              toDate={toDate}
            />;
  else if (match.params.home === "mappingPortal")
    return (
      <MappingPortal
        companyKey={companyKey}
        companyInfo={companyInfo}
      />
    );
  else if (match.params.home === "products") {
    if (match.params.type === undefined)
      return (
        <Products
          companyKey={companyKey}
          companyInfo={companyInfo}
          module={match.params.module}
          fromDate={fromDate}
          toDate={toDate}
        />
      );
    else {
      if (match.params.category === "base")
        return (
          <ProductEdit
            sku={match.params.type}
            category={match.params.category}
            companyKey={companyKey}
            companyInfo={companyInfo}
          />
        );
      else if (match.params.category === "variant")
        return (
          <ProductEdit
            sku={match.params.type}
            variantSku={match.params.sku}
            category={match.params.category}
            companyKey={companyKey}
          />
        );
      else if (match.params.category === "marketplace")
        return (
          <ProductEdit
            sku={match.params.type}
            category={match.params.category}
            companyKey={companyKey}
          />
        );
      else if (match.params.category === "images")
        return (
          <ProductEdit
            sku={match.params.type}
            category={match.params.category}
            companyKey={companyKey}
          />
        );
      else
        return (
          <ProductMainPage
            sku={match.params.type}
            companyKey={companyKey}
            companyInfo={companyInfo}
            fromDate={fromDate}
            toDate={toDate}
          />
        );
    }
  } 

  else if (match.params.home === "product-edit")
    return <ProductEditable companyKey={companyKey} />;

  else if (match.params.home === "orders") {
    if (match.params.module === undefined)
      return <Orders companyKey={companyKey} companyInfo={companyInfo} fromDate={fromDate}
      toDate={toDate}/>;
    else
      return (
        <OrderDetails
          order={match.params.type}
          companyKey={companyKey}
          companyInfo={companyInfo}
        />
      );
  } 

  else if (match.params.home === "analytics") {
    if (match.params.module === undefined)
      return <Analytics companyKey={companyKey} fromDate={fromDate}
      toDate={toDate}/>
  }
  
  else if (match.params.home === "analytics-report") {
    if (match.params.module === undefined)
      return <ReportContainer companyKey={companyKey} report={report}/>
  }
  
  else if (match.params.home === "settings") {   
    if (match.params.module === "data_sources")
      return <Settings companyKey={companyKey} />;
    else if (match.params.module === "account") {
      if (match.params.type === "reset_password")
        return <ResetPassword companyKey={companyKey} notify={notify}/>;
      else return <SettingsAccount companyKey={companyKey}/>;
    } else if (match.params.module === "users")
      return <SettingsUsers companyKey={companyKey} notify={notify} />;
    else return <Settings companyKey={companyKey} />;
  } 

  else if(match.params.home === "notifications"){
    return(
      <NotificationContainer companyKey={companyKey} notify={notify}/>
    )
  }
  
  else window.location = "/dashboard";
}

export default RenderedComponent;
