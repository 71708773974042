import React from 'react';
import ModalClass from './Modal'
import { Form, Modal, ModalHeader, ModalBody } from "reactstrap";
import { getRedirectUrl } from '../../../../services/registrationService';
import { toastify } from '../../../../common-functions/notify';

class ShopifyModal extends ModalClass {
  state = {
    data : {},
    checkboxes : {}
  }

  componentDidMount(){
    this.setDataFields()
  }
  setDataFields=()=>{
     const {fields} = this.props
     let data = {} 
     let checkboxes = {} 

     for(let i=0; i < fields.length; i++){
         if(!fields[i].invisible && fields[i].fieldtype === 'input'){
             data[fields[i].name] ={
                ...fields[i],
                value: ""
             }
         }

         else if(!fields[i].invisible && fields[i].fieldtype === 'checkbox'){            
             checkboxes[fields[i].name] = {
               ...fields[i],
               checked : false
            }
         }
     }
     this.setState({data, checkboxes})
  }

  doSubmit = async () => {
    const {companyKey, toggleModal, url} = this.props
    toggleModal()
    try{
      const formData = {
        'companyKey': companyKey,
        'shopifyStore': this.state.data['storeName'].value,
      }
      console.log(formData, url)
      const {data} = await getRedirectUrl(url, formData)
      if(data.result === 'success')
        window.open(data.url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
      else if(data.message)
        toastify('error', data.message)
      else
        toastify('error','Something went wrong.', 'Please try again later.')
      console.log(data)
    }
    catch(ex){
       toastify('error', "Oops. Connection failed.", "Please try again later.")
       console.log(ex)
    }
  }

  setCredentials = () => {
    const {data, checkboxes} = this.state

    let obj = {}

    for(let [key, value] of Object.entries(data)){
        obj[key] = value.value
    }
  
    for(let [key, value] of Object.entries(checkboxes)){
      obj[key] = value.checked
    }

    return obj
  }

  render() {
    const {className, isModalOpen, toggleModal} = this.props
    const { errors, data} = this.state
    if(Object.keys(data).length === 0) return null
    return (
      <Modal
          isOpen={isModalOpen}
          toggle={toggleModal}
          className={`product-modal ${className}`}
        >
          <ModalHeader className='integration-modal-header'>
            Integrate with Shopify
          </ModalHeader>
          <ModalBody className="integration-modal-body">
            {errors && <p className="error-msg">{errors}</p>}
            <Form className='m-3 integration-form' onSubmit={this.handleSubmit}>
                {this.renderInput(data['storeName'], 1)}
                <div className='modal-buttons mt-3 mb-3'>
                    {this.renderButton('modal-button-cancel', toggleModal, 'Cancel')}
                    {this.renderButton('modal-button-save', null , 'Continue')}
                </div>
            </Form>
          </ModalBody>
        </Modal>
    );
  }
}

export default ShopifyModal;