import React, { Component } from 'react';
import { toastify } from '../../../common-functions/notify';
import {editNotifications } from '../../../services/notificationService';
import Product from "../../../assets/product-1.png";
import Feed from "../../../assets/feeds.png";
import Order from "../../../assets/order-1.png";
import Inventory from "../../../assets/inventory.png";

class NotificationClass extends Component {
  state={
    data: {},
    error: {},
  }

  viewReport = (notification) => {
    this.props.saveReportUrl(notification)
  }

  deleteItem = async (notification) => {
    const formData = {
      processes : [{"type": 'delete', "processId": notification.id}],
    }
    console.log(JSON.stringify(formData))
    const res = await editNotifications(formData)
    console.log(res)
    if(res === 'success')
      toastify('success','Notification Deleted', '')
  }

  setImage = (type) => {
    if(type.includes('Inventory'))
        return Product
    else if(type.includes('Order'))
        return Order
    else if(type.includes('Product'))
        return Inventory
    else if(type.includes('Feed'))
        return Feed
    else
        return Feed
  }

  setDate = (date) => {
    let givenDate = new Date(date)
    let todayDate = new Date()
    
    if(this.areDatesEqual(givenDate, todayDate))
      return this.getTodayTime(todayDate, givenDate)
    
    return this.getDiff(givenDate, todayDate)
  }

  getTodayTime = (todayDate, givenDate) => {
    let time = Math.round((todayDate.getTime() - givenDate.getTime())/(1000*3600))

    if(time >= 1)
      return time +' hrs Ago'

    else {
      time = (todayDate.getTime() - givenDate.getTime())/(1000*60)
      if(time >= 5)
        return Math.round(time) +' mins Ago'
      else {
        return 'now'
      }
    }
  }

  areDatesEqual = (d1, d2) => {
    if(d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear())
      return true
    return false
  }

  getDiff = (d1, d2)=> {
    const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'Sept', 
                        'Oct', 'Nov', 'Dec']
    if(d1.getFullYear() !== d2.getFullYear())
      return Math.abs(d1.getFullYear() - d2.getFullYear()) + 'Years Ago'
    else {
      if(Math.abs(d1.getMonth() - d1.getMonth()) > 1)
        return Math.abs(d1.getMonth() - d2.getMonth()) + 'Months Ago'
      else 
        return months[d2.getMonth()] + " " + d1.getDate() 
    }
  }

  renderLoadingList = () => {
    let arr = [1, 2, 3];
    return(
      <React.Fragment>
        {arr.map((item, key)=> {
          return(
            <div className={`row notification-list-item loading`} key={key}>
              <div className="col-3 icon">
                <div></div>
              </div>
              <div className="col-8 content">
                <div></div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default NotificationClass;