import React from 'react';
import SelectBox from '../../UI Elements/SelectBox';

export const Row = ({title, options, onChange, selectedItem}) => {
  return(
      <div className="row col-12 m-2">
          <div className="col-12 col-md-2 col-lg-3 mt-2">{title}</div>
          <div className="col-12 col-md-4 col-lg-3">
              <SelectBox 
                  title = {title}
                  list ={options}
                  onChange={onChange}
                  selectedItem={selectedItem}
              />
          </div>
      </div>
  )
}