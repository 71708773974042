import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { setNumbers } from '../../../common-functions/setNumbers';

function ProductWidget({products, title, currencySymbol, loading}) {
    return (
        <div className={`d-flex flex-column widget-container p-3 ${loading ? 'active' : ''}`}>
            <div className="widget-container_1">
                <h5>{title}</h5>
            </div>
            <ul className={`${loading ? 'active' : null}`}>
                <li>
                    <div></div>
                    <div></div>
                </li>
                <li>
                    <div></div>
                    <div></div>
                </li>
                <li>
                    <div></div>
                    <div></div>
                </li>
                <li>
                    <div></div>
                    <div></div>
                </li>
                <li>
                    <div></div>
                    <div></div>
                </li>  
            </ul>
                
            <div className={`d-flex justify-content-between widget-container_2 ${loading ? 'active' : ''}`}>
                <div>Product Sku</div>
                <div>Sales </div>
            </div>
            <div className={`d-flex flex-column widget-container_3 ${loading ? 'active' : ''}`}>
                {Object.entries(products).map((item, key)=> {
                    if(key < 20)
                            return (
                                <Link to={`/products/analytics/${item[0]}`}
                                params={{ module:'analytics', type: item[0] }} key={key}>
                                    <div className="d-flex justify-content-between pt-2 pb-2">
                                        <div>
                                            <Image 
                                                src={!item[1].url ?
                                                            require("./../../../assets/no-image.png") : item[1].url
                                                    } 
                                                thumbnail 
                                                className="widget-img"/>
                                            <span>{item[0]}</span>
                                        </div>
                                        <div>
                                            <h5>
                                                {currencySymbol}
                                                {setNumbers(item[1].sales)}
                                            </h5>
                                        </div>
                                    </div>
                                </Link>
                                
                            )
                        else
                            return null
                })}
            </div>
        
            {!loading && products.length === 0 ? <h4>No data Available</h4> : null}
        </div>
    );
}

export default ProductWidget;