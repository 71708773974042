import React from 'react';
import Accordion from './Accordion/Accordion';

function RenderMarketAttr({mpFields, dragStart, selectedMp}) {
  const obj = mpFields
  return (
    <div className="map-col_mp">
        {Object.entries(obj).map((mp, key)=> {
            return( 
              <Accordion 
                title={mp[0]}
                fields={mp[1]}
                dragStart={dragStart}
                key={key}
                type={'mp'}
              />
            )
        })}
    </div>
  );
}

export default RenderMarketAttr;