import React from 'react';
import {Form, FormGroup, Col, Input, Label} from 'reactstrap';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function ShopifyTemplate(props) {
    const { attributeArray, tabSelected, subCategories} = props;
    return (
        <div>
            <Form>
                {Object.entries(attributeArray).map((item, key) => {
                    if(item[1].type === 'textarea' & item[1].visibility === true) {
                        return(
                            <FormGroup row key={key}>
                                <Label htmlFor="title" md={12}>{item[1].title}</Label>
                                <Col md={12}>
                                    <Input type="text" className="product-input" id={item[0]} name={item[0]} value={item[1].value} onChange={props.handleMarketPlaceBaseInput(tabSelected)} readOnly={!item[1].editable} />                                    
                                </Col>
                            </FormGroup>
                        );
                    }
                    else if(item[1].type === 'select' & item[1].visibility === true) {
                        if (item[0] === tabSelected.toLowerCase() + 'Category') {
                            let arr = JSON.parse(item[1].options);
                            let categories= [];
                            for(let i=0; i < arr.length; i++) {
                                for(var key1 in arr[i]) {
                                    categories.push(key1);
                                }                          
                            }
                            const uniqueSet = new Set(categories);
                            categories = [...uniqueSet];
                            return(
                                <FormGroup row key={key}>
                                    <Label htmlFor="title" md={12}>{item[1].title}</Label>
                                    <Select value={item[1].value} name={item[0]} onChange={props.handleSelect(item, tabSelected, categories)}>
                                        {categories.map(function(item1, key1){ 
                                        return (<MenuItem  key={key1} value={item1}>{item1}</MenuItem>)})}
                                    </Select>
                                </FormGroup>
                            );
                        }
                        else if(item[0] === tabSelected.toLowerCase() + 'SubCategory') {
                            return(
                                <FormGroup row key={key}>
                                    <Label htmlFor="title" md={12}>{item[1].title}</Label>
                                    <Select 
                                        value={item[1].value} 
                                        name={item[0]} 
                                        onChange={props.handleSubCategory(item, tabSelected)}>
                                        {subCategories.map((item1, key1)=>{
                                            return (<MenuItem  key={key1} value={item1}>{item1}</MenuItem>)
                                        })}
                                    </Select>
                                </FormGroup>
                            );
                        }             
                    }
                    else {
                        return null
                    }
                })}
            </Form>
        </div>
    );
}

export default ShopifyTemplate;