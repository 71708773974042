import React from 'react';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { ExcelRenderer } from 'react-excel-renderer';

import CheckBox from '../../UI Elements/CheckBox';
import Dropzone from '../../UI Elements/Dropzone';
import { setCsvName } from '../../../services/mappingPortalService';

function CSVUpload({stores, onStoreSelect, next, setCsvData}) {

  const getStoreTemplate = () => {
    if(stores){
      const selectedStores = stores.filter(item => item.checked === true)
      if(selectedStores.length > 0){
        let headers = selectedStores.reduce((temp, item)=> {
          temp.push(item.name)
          return temp
        },[])
    
        let finalData = [['Base', 'Variant',...headers]]
        return finalData
      }
      return null
    }
  }

  const onFileDrop =(data)=>{
    setCsvName(data.name)

    ExcelRenderer(data, (err, resp) => {
        setCsvData(resp['rows'])
        next()
    })
}
 
const onFileSelect = (event) => {
    setCsvName(event.target.files[0].name)
    
    ExcelRenderer(event.target.files[0], (err, resp) => {
        setCsvData(resp['rows'])
        next()
    })
}

  return (
    <div className="col-11 stores-list offset-1 m-3">
        <h5>Select Store</h5>
        {stores.map((item, key)=> {
          return(
            <CheckBox 
              label={item.name} 
              value={item.name}
              checked={item.checked}
              onCheckboxClick={(e, value)=>onStoreSelect(e, value)}
            />
          )
        })}
      {getStoreTemplate() ?<CSVLink data={getStoreTemplate()}>
          <Button>Download Template</Button>
      </CSVLink> : null}
        <div className="lower">
            <div className="container-fluid importProducts">
                <Dropzone onFileDrop={onFileDrop} onFileSelect={onFileSelect} />
            </div>
        </div>
    </div>
  );
}

export default CSVUpload;