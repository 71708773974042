import React from 'react';
import { Form, Modal, ModalHeader, ModalBody } from "reactstrap";
import { toastify } from '../../../../common-functions/notify';
import { integrate } from '../../../../services/registrationService';
import ModalClass from './Modal'

class EbayModal extends ModalClass {
  state = {
    data : {},
    checkboxes : {}
  }

  componentDidMount(){
    this.setDataFields()
  }

  setDataFields=()=>{
    const {fields} = this.props
    let data = {} 
    let checkboxes = {} 

    for(let i=0; i < fields.length; i++){
        if(!fields[i].invisible && fields[i].fieldtype === 'input'){
            data[fields[i].name] ={
               ...fields[i],
               value: ""
            }
        }

        else if(!fields[i].invisible && fields[i].fieldtype === 'checkbox'){            
            checkboxes[fields[i].name] = {
              ...fields[i],
              checked : false
           }
        }
    }
    this.setState({data, checkboxes})
  }

  setCredentials = () => {
    const {data, checkboxes} = this.state

    let obj = {}

    for(let [key, value] of Object.entries(data)){
        obj[key] = value.value
    }
  
    for(let [key, value] of Object.entries(checkboxes)){
      obj[key] = value.checked
    }

    return obj
  }
 
  doSubmit = async () => {
      const { data } = this.state
      const { addStores} = this.props

      this.props.toggleModal()

      try{
        const formData = {
          companyKey: this.props.companyKey,
          marketplace: 'Ebay',
          credentials: {
            [data.storeName.value]: {
              ...this.setCredentials()
            },
          },
        }
        const resp = await integrate(formData)

        if (resp.data.result === "Active") {
          toastify('success', "Successfully Connected", "Your store is active in Ebay now.")
          let object = {
            marketPlace: 'Ebay',
            storeName : data.storeName,
            uploadStatus: 0, //0% initially
            uploadLabel: "Connecting",
            lastSync: "",
          }
          addStores(object, resp.data.processes[0])          
        }
      }
      catch(ex){
        toastify('error', "Oops. Connection failed.", "Please try again later.")
         console.log(ex)
      }
  }
  
  render(){
    const {className, isModalOpen, toggleModal} = this.props
    const { errors, data, checkboxes} = this.state

    return (
      <Modal
          isOpen={isModalOpen}
          toggle={toggleModal}
          className={`product-modal ${className}`}
        >
          <ModalHeader className='integration-modal-header'>
            Integrate with Ebay
          </ModalHeader>
          <ModalBody>
            {errors && <p className="error-msg">{errors}</p>}
            <Form className='m-3' onSubmit={this.handleSubmit}>
                {Object.entries(data).map((field, key) => {
                      return this.renderInput(field[1], key)                     
                   })}
                {this.renderCheckbox('Update', checkboxes,  'update', 'Ebay', 2)}
                {this.renderCheckbox('Sync', checkboxes,  'sync', 'Ebay', 2)}
                
                <div className='modal-buttons mt-3 mb-3'>
                    {this.renderButton('modal-button-cancel', toggleModal, 'Cancel')}
                    {this.renderButton('modal-button-save', null , 'Save')}
                </div>
            </Form>
          </ModalBody>
      </Modal>      
    )
  }
}

export default EbayModal;