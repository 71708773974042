import React from 'react';
import { Link } from 'react-router-dom';
import { setNumbers } from '../../../../common-functions/setNumbers';
import { saveProductPosition } from '../../../../services/productService';
import LoadingContent from '../../../common/LoadingContent';

function ProductTitle({currencySymbol ,loadingDataPoints ,product}) {
  
  const renderSalesData = (sales, salesChange) => {
    return(
    <React.Fragment>
        {currencySymbol}
        {sales ? setNumbers(sales) : null}
        <span className={`pl-2 ${salesChange > 0 ? 'green' : 'red'}`} >
            {salesChange !== 'NA'? 
              salesChange > 0 ?
              '+'+ setNumbers(salesChange) +'%'
              : setNumbers(salesChange) +'%'
            : '' }
        </span>
    </React.Fragment>
    )
  }

  return (
    <div className='col-8 col-md-8 col-lg-9'>
        <div className='product-card_title'>
          <Link
              className='product-a'
              to={`/products/analytics/${product.baseKey}`}
              params={{ type: product.baseKey }}
              onClick={() =>saveProductPosition(product.position)}
            >
              {product["baseSku"]}
          </Link>
        </div>
        <div 
            className="product-card_title"
            data-for="custom-color-no-arrow"
            data-tip={product['title']}
            data-iscapture="true"
            >
            {product['title']}
        </div>
        <div className={`product-card_title ${loadingDataPoints ? 'loading' : ''}`}>
            {loadingDataPoints ?
            <LoadingContent width={'80%'} height={'13px'}/>
            :
            renderSalesData(product["Total Sales"], product['Sales Change'])
            }                            
        </div>
        <div className={`product-card_title`}> 
          {loadingDataPoints ?
            <LoadingContent width={'60%'} marginTop={'10px'} height={'13px'}/>
            :
            <span>Net Sales</span>
            }
        </div>
    </div>
  );
}

export default ProductTitle;