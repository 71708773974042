import React, { Component } from 'react';
import LineGraph from './LineGraph';
import Dropdown from '../../UI Elements/Dropdown';
import { setNumbers } from '../../../common-functions/setNumbers';

class GraphTabs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardObject: {
                'CURRENT PERIOD': {
                    "value": '',
                    "duration": '',
                    "rate" : null
                },
                'PREVIOUS PERIOD': {
                    "value": 'N/A',
                    "duration": '',
                    "rate" : null
                },
                // 'PREVIOUS YEAR PERIOD': {
                //     "value": 'N/A',
                //     "duration": '',
                //     "rate" : null
                // }
            },
            marketplaces : [],
            finalDataArr : [],
            allDates : [],
            dropdownTitle: ['All'],
            mpData : [],
            selectedMPArr: [],
            title: '',
            msg: ''
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        }, () => {
            this.setDataInGraph()
        })
    }
    componentDidMount(){
        const {fromDate, toDate, data, graph } = this.props
        //set duration in cards
        this.setDate(fromDate, toDate)

        if(Object.keys(data).length > 0 & Object.keys(graph).length > 0){
            // set date array for line graph data  2-d array
            const allDates = [...this.setDateArray(fromDate, toDate)]

            //set marketplace dropdown
            const arr = [...Object.keys(data[graph['graph']]['chartData'])]
            const marketplaces = this.setMarketPlaceDropdown(arr)

            this.setState({
                allDates : allDates,
                marketplaces : marketplaces,
                title: graph.title
            }, () => this.setDataInGraph())
        }
    }
    componentDidUpdate(prevProps, prevState){
        const {fromDate, toDate, data, graph } = this.props
        if(prevProps.graph !== this.props.graph || prevProps.companyKey !== this.props.companyKey || prevProps.loading !== this.props.loading || prevProps.fromDate !== this.props.fromDate || prevProps.toDate !== this.props.toDate){
            if(!data[graph['graph']] || this.props.graph['graph'] === null)
            {
                this.setState({
                    msg: 'Not Available',
                    finalDataArr: []
                })
            }
            else{
                //set duration in cards
                this.setDate(fromDate, toDate)
                const allDates = [...this.setDateArray(fromDate, toDate)]

                //set marketplace dropdown
                const arr = [...Object.keys(data[graph['graph']]['chartData'])]
                const marketplaces = this.setMarketPlaceDropdown(arr)
                this.setState({
                    allDates : allDates,
                    marketplaces : marketplaces,
                    title: graph.title,
                    dropdownTitle : ["All"]
                }, () => this.setDataInGraph())
            }
        }
    }
    setDataInGraph =() =>{
        const { data, graph } = this.props
        
        let mpData = []

        //set all marketplaces in array 
        mpData = this.setAllMpData(data[graph['graph']]['chartData'])

        this.setState({
            mpData : mpData,
        }, () => this.displayGraph()) 
    }
    calculateRate= (str1, str2) => {
        let num1 = parseFloat(str1)
        let num2 = parseFloat(str2)

        return Math.round((num1 - num2)/num1 * 100).toFixed(2)
    }
    setCards = () => {
        const { data, currencySymbol, graph } = this.props
        const { selectedMPArr } = this.state

        let rate = null

        let  {cardObject}  = this.state
        let total = 0
        let prevTotal = 0

        if(selectedMPArr.includes('All')){
            for(let [key, value] of Object.entries(data[graph['graph']]['chartTotal'])){
                total += value
            }
            for(let [key, value] of Object.entries(data[graph['graph']]['prevTotal'])){
                prevTotal += value
            }
        }
        else{
            for(let i=0; i < selectedMPArr.length; i++){
                if(data[graph['graph']]['chartTotal'][selectedMPArr[i]])
                    total += data[graph['graph']]['chartTotal'][selectedMPArr[i]]
                else   
                    total+=0
            }
            for(let i=0; i < selectedMPArr.length; i++){
                if(data[graph['graph']]['prevTotal'][selectedMPArr[i]])
                    prevTotal += data[graph['graph']]['prevTotal'][selectedMPArr[i]]
                else
                    prevTotal+=0
            }
        }

        if(graph['graph'] === 'LINE_ORDER_SALES' || graph['graph'] === 'LINE_RETURN_ORDER_SALES'){
            cardObject['CURRENT PERIOD']['value'] = currencySymbol + setNumbers(total)
            cardObject['PREVIOUS PERIOD']['value'] = currencySymbol + setNumbers(prevTotal)

            rate = this.calculateRate(total, prevTotal)
            cardObject['CURRENT PERIOD']['rate'] = rate === 0.00 ? null : rate
        }
        else{
            cardObject['CURRENT PERIOD']['value'] = setNumbers(total)
            cardObject['PREVIOUS PERIOD']['value'] = setNumbers(prevTotal)
            rate = this.calculateRate(total, prevTotal)
            cardObject['CURRENT PERIOD']['rate'] = rate === 0.00 ? null : rate 
        }
        this.setState({
            cardObject: cardObject
        })

    }
    setDateArray = (from, to) =>{
        const {data } = this.props
        let date_arr = []       
        
        let temp_arr = [...Object.keys(data['LINE_ORDERS']['chartData']['All'])]
        for(let i=0; i < temp_arr.length; i++){
            date_arr.push(temp_arr[i])
        }
        return date_arr
    }
    setMarketPlaceDropdown = (arr) => {
        let new_arr = []
        for(let i=0; i < arr.length; i ++ ){
            if(arr[i] === 'All'){
                new_arr.push({
                    name : arr[i],
                    checked :  true 
                }) 
            }
            else{
                new_arr.push({
                    name : arr[i],
                    checked : false
                })
            }   
                                                     
        }
        return new_arr 
    }
    setAllMpData = (obj) => {
        const temp_arr = []
        for(let [key, value] of Object.entries(obj)){
            var sales_data = []
            for( let [date, sales] of Object.entries(value)){
                sales_data.push({
                    date : date,
                    value : sales
                })
            }
            temp_arr.push({
                name: key,
                data : [...sales_data]
            })
        }
        return temp_arr
    }
    setDate =(fromDate, toDate)=> {
        const {cardObject} = this.state
        const {d1, d2} = this.getDate()

        const months = ['JAN', 'FEB', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPT', 
                        'OCT', 'NOV', 'DEC']

        // set current period
        const str1 = months[d1.getMonth()] + ' ' + d1.getDate() + ', ' + d1.getFullYear()
        const str2 = months[d2.getMonth()] + ' ' + d2.getDate() + ', ' + d2.getFullYear()
        cardObject['CURRENT PERIOD']['duration']= str1 +' - '+ str2

        const diffTime = Math.abs(d2.getTime() - d1.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        
        const prevFromDate = new Date((d1.getTime()/1000 - (86400*diffDays))*1000)
        const prevToDate = new Date((d2.getTime()/1000 - (86400*diffDays))*1000)

        const str3 = months[prevFromDate.getMonth()] + ' ' + prevFromDate.getDate() + ', ' + prevFromDate.getFullYear()
        const str4 = months[prevToDate.getMonth()] + ' ' + prevToDate.getDate() + ', ' + prevToDate.getFullYear()
        cardObject['PREVIOUS PERIOD']['duration']= str3 +' - '+ str4

        // const str5 = months[d1.getMonth()-1] + ' ' + d1.getDate() + ', ' + (d1.getFullYear()-1).toString()
        // const str6 = months[d2.getMonth()-1] + ' ' + d2.getDate() + ', ' + (d2.getFullYear()-1).toString()
        // cardObject['PREVIOUS YEAR PERIOD']['duration']= str5 +' - '+ str6

        this.setState({cardObject})
    }
    getDate= ()=> {
        const {fromDate, toDate} = this.props
        let d1 = 0
        let d2 = 0
        if(fromDate.length === 10){
            d1 = new Date(fromDate[6]+fromDate[7]+fromDate[8]+fromDate[9], fromDate[3]+fromDate[4]-1, fromDate[0]+fromDate[1]) 
        }
        else{
            d1 = new Date(fromDate[5]+fromDate[6]+fromDate[7]+fromDate[8], fromDate[2]+fromDate[3]-1, fromDate[0])
        }
        if(toDate.length === 10){
          d2 = new Date(toDate[6]+toDate[7]+toDate[8]+toDate[9], toDate[3]+toDate[4]-1, toDate[0]+toDate[1])    
        }
        else{
          d2 = new Date(toDate[5]+toDate[6]+toDate[7]+toDate[8], toDate[2]+toDate[3]-1, toDate[0])
        }
        return {d1, d2}
    }
    handleMarketplaceSelection = (item) => {
        let {marketplaces} = this.state
        marketplaces[marketplaces.indexOf(item)].checked = !marketplaces[marketplaces.indexOf(item)].checked
        this.setState({
            marketplaces : marketplaces,
            loading : true
        }, () => {
            const  dropdownTitle  = []
            for(let i=0; i < marketplaces.length; i++){
                if(marketplaces[i].checked === true){
                    dropdownTitle.push(marketplaces[i].name)
                }
            }
            this.setState({
                dropdownTitle
            }, () => this.displayGraph())
            
        })
    }
    displayGraph =() => {
        const {marketplaces, mpData, allDates} = this.state
        const {dateType} = this.props
        let finalDataArr = new Array(allDates.length +1)

        let selectedMP = []
        let selectedMPArr = []
        for(let i=0; i < marketplaces.length; i++){
            if(marketplaces[i].checked === true){
                for(let j=0; j < mpData.length; j++){
                    if(mpData[j].name === marketplaces[i].name){
                        selectedMP.push(mpData[j])
                        selectedMPArr.push(mpData[j].name)
                    }
                }
            }
        }

        for (let i = 0; i < finalDataArr.length; i++) { 
            finalDataArr[i] = new Array(selectedMP.length)
        } 
        if(dateType === 'month'){
            for(let i=1; i <= allDates.length; i++){           
                for(let j=0; j< 1; j++){
                    finalDataArr[i][j] = allDates[i-1]                   
                }            
            }
        }
        else{
            for(let i=1; i <= allDates.length; i++){           
                for(let j=0; j< 1; j++){
                    finalDataArr[i][j] = new Date(allDates[i-1])
                }            
            }
        }

        for(let i=0; i < selectedMP.length; i++){
            finalDataArr[0][0] = dateType === 'month' ? { type: 'string', label: '' } : {type: 'date', label: ''}
            finalDataArr[0][i+1] = selectedMP[i]['name'] 

            for(let j=0; j < allDates.length; j++){
                if(selectedMP[i]['data'][j] === undefined){
                    finalDataArr[j+1][i+1] = 0
                }
                else {
                    finalDataArr[j+1][i+1] = selectedMP[i]['data'][j]['value']
                }    
            }           
        }

        this.setState({
            finalDataArr : finalDataArr,
            loading : false,
            selectedMPArr: selectedMPArr
        }, () => this.setCards())
    }
    render() {
        const { cardObject, marketplaces, finalDataArr, dropdownTitle , selectedMPArr, currencySymbol, title, msg} = this.state
        const {loading} = this.props
        return (
            <div className="container-fluid line-graph-tabs">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        {finalDataArr.length === 0 && !loading ? null :
                            <div className={`row marketplace-dropdown ${loading ? 'active' : ''}`}>
                                <Dropdown 
                                    title={dropdownTitle.length > 1 ? `${dropdownTitle.length} Selected` : dropdownTitle[0]} 
                                    data={marketplaces} 
                                    handleCheckbox={this.handleMarketplaceSelection}
                                />
                            </div>
                        }
                        <div className="settings-tab">
                            {finalDataArr.length === 0 && !loading ?
                                //<h5 className="p-3">{msg}</h5>
                                <p className="not-available-msg">No Data Available</p>
                                :
                                <React.Fragment>
                                    <h5 className={`p-3 ${loading ? 'active': ''}`}>
                                        { !loading && title }
                                    </h5>
                                    <LineGraph  
                                        data={finalDataArr}
                                        setDateType={this.props.setDateType} 
                                        dateType={this.props.dateType} 
                                        dateArray={this.props.dateArray}
                                        selectedMPArr={selectedMPArr}
                                        currencySymbol={currencySymbol}
                                        loading={loading}
                                    />
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 graphTab">
                        {finalDataArr.length === 0 && !loading ? null :
                            <div className={`row marketplace-dropdown ${loading ? 'active' : ''}`}>
                                <Dropdown 
                                    title={dropdownTitle.length > 1 ? `${dropdownTitle.length} Selected` : dropdownTitle[0]} 
                                    data={marketplaces} 
                                    handleCheckbox={this.handleMarketplaceSelection}
                                />
                            </div>
                        }
                        <div className="row graphTab_row">
                            { !loading && finalDataArr.length === 0 ?
                                null :
                                Object.entries(cardObject).map((item, key) => {
                                    return (
                                        <div className="col-12 col-md-4 col-lg-12" key={key}>
                                            <h6 className={`${loading ? 'active' : ''}`}>
                                                {!loading && item[0]}
                                            </h6>
                                            <h4 className={`${loading ? 'active' : ''}`}>
                                                { !loading && item[1].value} &nbsp;
                                                { !loading && item[1].rate !== null ? 
                                                    <span className={item[1].rate < 0 ? `low` : `high`}>
                                                        { item[1].rate > 0 ? '+' + item[1].rate : item[1].rate }%
                                                    </span> 
                                                : null }
                                            </h4>
                                            <span className={`${loading ? 'active' : ''}`}>
                                                {!loading && item[1].duration}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GraphTabs;