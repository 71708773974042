import React from 'react';
import { setNumbers } from '../../../../common-functions/setNumbers';
import ProductImage from './ProductImage';
import DataPoint from './DataPoint';
import ProductTitle from './ProductTitle';

function Container({currencySymbol, product, enlargeImage, loadingDataPoints, onProductSelect, id}) {

  const getBrokenData = ()=> {
    if(product["Broken %"] !== null || product["Broken %"] !== undefined){
      let val = setNumbers(product["Broken %"])
      if(val === 0)
        return {text: 'All in Stock', customClass:'green', id: 1}
      else if(val === '100')
        return {text: 'Out of Stock', customClass:'alert', id: 1}
      else  
        return {label: '', value: val, progress:true, tooltip: 'broken_'+product.baseKey, id: 1}
    }
    return {label: 'Broken %', value: 'N/A', id: 1}
  }

  const dataPoints = {
    ROS: {
       label: 'ROS',
       id: 1,
       value: setNumbers(product['Rate of Sale']),
    },
    ASP: {
      label: 'ASP',
      currency: currencySymbol,
      value: setNumbers(product['Average Sale Price']),
    },
    Broken:{
      ...getBrokenData(),
    },
    Inventory:{
      label: 'INV',
      value: setNumbers(product['Current Quantity']),
      tooltip: 'inventory_'+product.baseKey
    }
  }

  return (
    <div className='container-fluid'>
        <div className='row'>
            <ProductImage enlargeImage={enlargeImage} product={product} />
            <ProductTitle 
              currencySymbol={currencySymbol}
              loadingDataPoints={loadingDataPoints}
              product={product} 
            />
        </div>
        <div className='row data-points mt-2'>
          <div className='col-8 d-flex flex-column data-points_1'>
            <div className='d-flex flexbox1 justify-content-between'>
                <DataPoint {...dataPoints['ROS']} loadingDataPoints={loadingDataPoints}/>
                <DataPoint {...dataPoints['ASP']} loadingDataPoints={loadingDataPoints}/>
            </div>
            <div className='d-flex flexbox2 justify-content-between'>
                <DataPoint {...dataPoints['Broken']} loadingDataPoints={loadingDataPoints}/>
                <DataPoint {...dataPoints['Inventory']} loadingDataPoints={loadingDataPoints}/>
            </div>
          </div>
          <div className='col-4 d-flex flex-column data-points_2'>          
              <div className={`pt-4 divs ${loadingDataPoints ? 'loading' : ''}`}>
                  <h5>{product["Total Orders"]}</h5>
                  {!loadingDataPoints && <h6>Orders</h6>}
              </div>
          </div>
        </div>
    </div>
  );
}

export default Container;