import React, {useState, useEffect} from 'react';
import Pagination from '../../../common/Pagination';
import { paginate } from '../../../../common-functions/paginate';
import { setNumbers } from '../../../../common-functions/setNumbers';
import _ from "lodash";

const columns = [
    {
        path : 'orderNo', label: 'ORDER NO.'
    },
    {
        path : 'orderDate', label: 'ORDERED DATE'
    },
    {
        path : 'sku', label: 'SKU'
    },
    {
        path : 'price', label: 'PRICE'
    },
    {
        path : 'quantity', label: 'QUANTITY'
    },
    {
        path : 'marketplace', label: 'MARKET PLACE'
    }
]

function Orders(props) {
  const limit = 5
  const {productOrders, currencySymbol, loadingStatus} = props

  const [searchedValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [sortColumn, setSortColumn] = useState({ path: "price", order: "desc" })

  useEffect(() => {
  }, [productOrders])

  const getPagedData = () => {
    let filtered = []
    if(searchedValue){
        filtered = productOrders.filter(item => item['orderNo'].toLowerCase().includes(searchedValue.toLowerCase()) ||  item['sku'].toLowerCase().includes(searchedValue.toLowerCase()))       
    }
    else
        filtered = [...productOrders]
    
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const arr = paginate(sorted, currentPage, limit)
    return { totalCount: filtered.length, data: arr }
  }

  const search = (event) => {
      const {value} = event.target
      setSearchValue(value)  
  }
  const changePage = (pageNo) => {
    setCurrentPage(pageNo)
  }

  const raiseSort = (path) => {
    const tempSortColumns = { ...sortColumn}
    if (tempSortColumns.path === path)
      tempSortColumns.order = tempSortColumns.order === "asc" ? "desc" : "asc"
    else {
      tempSortColumns.path = path
      tempSortColumns.order = "asc"
    }
    setSortColumn(tempSortColumns)
  }

  const renderSortIcon = (column) => {
    if (column.path !== sortColumn.path) 
        return null
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
        return <i className="fa fa-sort-desc" />;
  }

  const { totalCount, data : arr} = getPagedData()

  return (
    <div className="product-orders p-3">
        <div className="row">
            <div className="col-6 col-lg-3">
                <h5>Recent Orders</h5>
            </div>
            <div className="col-6 col-lg-4">
                <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input className="form-control searchbar" type="text" placeholder="Search" name="searchedValue" value={searchedValue} aria-label="Search" onChange={search}/>
                </div>
            </div>
            <div className="col-12 col-lg-4 offset-lg-1">
                <Pagination 
                    count={totalCount}
                    currentPage={currentPage}
                    pageSize={limit}
                    changePage={changePage}
                />
            </div>
        </div> 

        {/* Orders List  */}
        <div className="wrapped-div-orders">
            <div className="row mt-3 table-header recent_orders">
                {columns.map((item, key) => {
                    return(
                        <div className="table_col" onClick={() =>raiseSort(item.path)}>
                            <h6> {item.label} {renderSortIcon(item)}</h6>
                        </div>
                    )
                })}
            </div>
            {arr.length === 0 && !loadingStatus ? <p className="not-available-msg">No results found</p> : null}
            {arr.map((item, key) => {
                return(
                    <div className="row listing-row recent_orders" key={key}>
                        <div className="table_col">
                            <div className="p-1 mt-2 orderNo">
                                {item.orderNo}
                            </div>  
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {item.orderDate}
                            </div>  
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {item.sku}
                            </div>
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {currencySymbol}{setNumbers(item.price)}
                            </div>
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {item.quantity}
                            </div>
                        </div>
                        <div className="table_col">
                            <div className="p-1 mt-2">
                                {item.marketplace}
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    </div>
                    
  );
}

export default Orders;