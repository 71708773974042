import React, {useEffect, useState } from 'react';
import { Card, CardText, CardBody } from "reactstrap";

import amazon from "../../../assets/amazon-icon.png";
import shopify from "../../../assets/shopify.png";
import ebay from "../../../assets/ebay-logo.png";
import walmart from "../../../assets/walmart-logo.jpg";
import quickbooks from "../../../assets/quickbooks.jpg";
import AmazonModal from './Modals/AmazonModal';
import EbayModal from './Modals/EbayModal';
import ShopifyModal from './Modals/ShopifyModal';
import WalmartModal from './Modals/WalmartModal';
import { getMarketplaces, getRedirectUrl } from '../../../services/registrationService';
import { toastify } from '../../../common-functions/notify';

function OtherStores({isModalOpen, toggleModal, companyKey, addStores}) {
  const [marketplaceArr, setMarketplaceArr] = useState([])
  const [marketplace, setMarketplace] = useState('')

  const getMarketplaceData = async () => {
    const formData = {
      companyKey: companyKey
    }
    return await getMarketplaces(formData)
  }

  useEffect(() => {
    getMarketplaceData().then(res=> {
      console.log(res.data)
        setMarketplaceArr(res.data)
    })
  }, [])

  const storeImage = (store) => {
    if (store === "Amazon")
      return (
        <img src={amazon} alt='store-img-amazon' style={{ height: "30px" }} />
      );
    if (store === "Shopify")
      return (
        <img src={shopify} alt='store-img-shopify' style={{ height: "25px" }} />
      );
    if (store === "Ebay")
      return <img src={ebay} alt='store-img-ebay' style={{ height: "25px" }} />;
    if (store === "Quickbooks")
      return <img src={quickbooks} alt='store-img-quickbooks' style={{ height: "35px" }} />;
    if (store === "Walmart")
      return (
        <img src={walmart} alt='store-img-walmart' style={{ height: "25px" }} />
      );
  }

  const onConnect = async (e, mp) => {
    setMarketplace(mp[0])
    if(mp[1].connectionType === 'POPUP' || mp[1].connectionType === 'POPUP_REDIRECT'){
       toggleModal(e)
    }
    else if(mp[1].connectionType === 'REDIRECT'){
      const {data:{result, url}} = await getRedirectUrl(mp[1].connectionUrl)
      if(result === 'success')
        window.open(url);
      else  
        toastify('error', 'Something went wrong.', 'Please try again later.')
    }
  }

  const generateModal = () => {
    if (marketplace === "Amazon") 
        return (<AmazonModal 
                    isModalOpen={isModalOpen} 
                    toggleModal={toggleModal} 
                    className={'amazon-integration'}
                    companyKey={companyKey}
                    addStores={addStores}
                    fields={marketplaceArr[marketplace].fields}
                />)

    if (marketplace === "Ebay") {
      return (<EbayModal 
                  isModalOpen={isModalOpen} 
                  toggleModal={toggleModal} 
                  className={'ebay-integration'}
                  companyKey={companyKey}
                  addStores={addStores}
                  fields={marketplaceArr[marketplace].fields}
              />)
    }

    if (marketplace === "Shopify") {
      return (<ShopifyModal 
                isModalOpen={isModalOpen} 
                toggleModal={toggleModal} 
                className={'shopify-integration'}
                companyKey={companyKey}
                addStores={addStores}
                fields={marketplaceArr[marketplace].fields}
                url={marketplaceArr[marketplace].connectionUrl}
            />)
    }
          
    if (marketplace === "Walmart") 
        return (<WalmartModal 
                    isModalOpen={isModalOpen} 
                    toggleModal={toggleModal} 
                    className={'walmart-integration'}
                    companyKey={companyKey}
                    addStores={addStores}
                    fields={marketplaceArr[marketplace].fields}
                />)

    else 
      return null
  }

  const productCustomUpload = () => {
    return(
    <div className="col-12 col-md-6 col-lg-4">
      <Card key={5} className='integration-product-settings-card'>
        <div className='integration-product-settings-image'>
            <img src={require('../../../assets/fileUpload.png')} alt='store-img-custom' style={{ height: "25px" }} />
            <span className="p-2">Product Custom Upload</span>
        </div>
        <CardBody>
          <CardText style={{ textAlign: "center", fontSize:'13px' }}>
            Manually upload your Products and sync them later with marketplaces
          </CardText>
          <button className='integration-button' onClick={openMappingPortal}>
              Upload
          </button>
        </CardBody>
      </Card>
    </div>
    )
  }

  const openMappingPortal = () => {
     window.location = '/mappingPortal'
  }

  
  if(marketplaceArr.length === 0)
    return null

  return (
    <React.Fragment>
        {Object.entries(marketplaceArr).map((marketplace, key) => {
          return (
            <div className="col-12 col-md-6 col-lg-4" key={key}>
              <Card key={key} className='integration-product-settings-card'>
                <div className='integration-product-settings-image'>
                  {storeImage(marketplace[1].class)}
                </div>
                <CardBody>
                  <CardText style={{ textAlign: "center", fontSize:'13px' }}>
                    Connect your {marketplace[0]} account and sync all
                    your products.
                  </CardText>
                  <div className="d-flex country-flags">
                    {marketplace[1].country.map((item, key)=>{
                      return(
                        <div className="p-1 mr-1">
                          <img className="country-flag" src={`https://www.countryflags.io/${marketplace[1].country[key]}/shiny/64.png`}/>
                        </div>
                      )
                    })}

                  </div>
                  <button className='integration-button' onClick={(e) =>onConnect(e, marketplace)}>
                    Connect
                  </button>
                </CardBody>
              </Card>
            </div>
          )
        })}
        {productCustomUpload()}
        {generateModal()}
      </React.Fragment>
  );
}

export default OtherStores;