import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button } from 'reactstrap';

function MapColumnModal({modalShow, onHide, stores, onCheckboxClick}) {
  useEffect(() => {

  }, [stores])
  if(stores.length === 0)
    return null
  return (
    <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                Select Store
            </Modal.Title>
            <Button onClick={onHide}>Close</Button>
        </Modal.Header>
        <Modal.Body>
            <ul>
                {stores.map((store, key)=> {
                    return(
                        <li key={key}>
                            <label className="custom-checkbox">
                                {store['store']}
                                <input 
                                    type="checkbox"
                                    name={store['store']}
                                    onClick={() =>onCheckboxClick(key, store['store'])}
                                    defaultChecked={store['checked']}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    )
                })}
            </ul>
        </Modal.Body>
    </Modal>
  );
}

export default MapColumnModal;