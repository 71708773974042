import React, { Component } from "react";
import logo from "../../assets/lyzer-logo.png";
import { setLoginData } from "../../services/authService";
import { registerWithShopify } from "../../services/registrationService";
import LoadingAnimation from "../common/LoadingAnimation";
import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";
import ShopifyForm from "./shopifyForm";

class FrontPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location : this.props.match.url,
      loading : false,
      data : {},
      error: ''
    }
  }

  componentDidMount(){
    if(this.state.location === '/shopify'){
        const urlParams = new URLSearchParams(window.location.search)       
        this.setState({
          shop : urlParams.get('shop'),
          auth : urlParams.get('auth'),
        })
        this.getShopifyData(urlParams.get('shop'), urlParams.get('auth'))      
    }
    else  
        this.setState({loading : false})
  }

  changeLoadingStatus = (val)=> {
     this.setState({loading : val})
  }

  setError = (msg)=> {
    this.setState({error: msg})
  }

  getShopifyData = async (shop, accessToken) => {
    const formData = {
      'shop' : shop,
      'access-token' : accessToken
    }
    const {data, headers} = await registerWithShopify(formData)
    if(data['result'] === 'success'){
        if(!data['exists']){
            this.setState({
               data: data,
               loading: false
            })
        }
        else{
            let res = await setLoginData(data, headers["x-auth-token"])
            if(res === 'success')
                window.localStorage='/dashboard'
        }
    }
  }

  renderForm=()=>{
    const { location, data, loading, error } = this.state;

    if(location=== '/login')
        return <LoginForm loading={loading} onLoadingChange={this.changeLoadingStatus} error={error} setError={this.setError}/>   
    else if(location === '/register')
       return <RegisterForm loading={loading} onLoadingChange={this.changeLoadingStatus}/>
    else if(location === '/register/shopify')
       return <RegisterForm shopifyData={data} loading={loading} onLoadingChange={this.changeLoadingStatus}/>
    else if(location === '/shopify')
        return <ShopifyForm data={data} openRegistration={this.openRegistration}/>
    else
        return <LoginForm loading={loading} onLoadingChange={this.changeLoadingStatus} error={error} setError={this.setError}/>
  }

  openRegistration = (shopifyData)=> {
     this.setState({
        location : '/register/shopify',
        data : shopifyData
     })
  }

  render() {
    const {loading} = this.state
    if(loading === true)
        return (<LoadingAnimation message={'Logging in'} page={'login'}/>)
    return (
      <div className='container-fluid front-page'>
        <div className='front-page_form'>
          <div className='row row1'>
            <div className='col-12 col-lg-6 col1 p-3'>
              <div className='logo'>
                <img src={logo} alt='logo' />
              </div>
              <h1 className='front-page_primary-heading'>
                Welcome to <span>Lyzer</span>
              </h1>
            </div>
            <div className='col-12 col-lg-6 col2 p-3'>
                {this.renderForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FrontPage;
