import React from 'react';

function SortProducts(props) {
  return (
	<ul>
		<li>
			<label className='container-radio-sort'>
				Total Sales 90 days &#8595;
				<input
					type='radio'
					name='sorting'
					onChange={props.sortProducts('totalSales90', 'desc')}
				/>
				<span className='checkmark-radio-sort' />
			</label>
		</li>
		<li>
			<label className='container-radio-sort'>
				Total Sales 90 days &#8593;
				<input
					type='radio'
					name='sorting'
					onChange={props.sortProducts('totalSales90', 'asc')}
				/>
				<span className='checkmark-radio-sort' />
			</label>
		</li>
		<li>
			<label className='container-radio-sort'>
				Total Sales 30 days &#8595;
				<input
					type='radio'
					name='sorting'
					defaultChecked={true}
					onChange={props.sortProducts('totalSales30', 'desc')}
				/>
				<span className='checkmark-radio-sort' />
			</label>
		</li>
		<li>
			<label className='container-radio-sort'>
				Total Sales 30 days &#8593;
				<input
					type='radio'
					name='sorting'
					onChange={props.sortProducts('totalSales30', 'asc')}
				/>
				<span className='checkmark-radio-sort' />
			</label>
		</li>
		<li>
			<label className='container-radio-sort'>
				Score &#8595;
				<input
					type='radio'
					name='sorting'
					onChange={props.sortProducts('score', 'desc')}
				/>
				<span className='checkmark-radio-sort' />
			</label>
		</li>
		<li>
			<label className='container-radio-sort'>
				Score &#8593;
				<input
					type='radio'
					name='sorting'
					onChange={props.sortProducts('score', 'asc')}
				/>
				<span className='checkmark-radio-sort' />
			</label>
		</li>
		<li>
			<label className='container-radio-sort'>
				Inventory &#8595;
				<input
					type='radio'
					name='sorting'
					onChange={props.sortProducts('totalQuantity', 'desc')}
				/>
				<span className='checkmark-radio-sort' />
			</label>
		</li>
		<li>
			<label className='container-radio-sort'>
				Inventory &#8593;
				<input
					type='radio'
					name='sorting'
					onChange={props.sortProducts('totalQuantity', 'asc')}
				/>
				<span className='checkmark-radio-sort' />
			</label>
		</li>
		<li>
			<label className='container-radio-sort'>
				ROS 30 Days &#8595;
				<input
					type='radio'
					name='sorting'
					onChange={props.sortProducts('ros30', 'desc')}
				/>
				<span className='checkmark-radio-sort' />
			</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					ROS 30 Days &#8593;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('ros30', 'asc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					ROS 90 days &#8595;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('ros90', 'desc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					ROS 90 days &#8593;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('ros90', 'asc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>					
			<li>
				<label className='container-radio-sort'>
					AOS &#8595;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('aos', 'desc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					AOS &#8593;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('aos', 'asc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					Age &#8595;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('age', 'desc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					Age &#8593;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('age', 'asc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					Lifetime Orders &#8595;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('totalOrders', 'desc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					Lifetime Orders &#8593;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('totalOrders', 'asc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					Lifetime Revenue &#8595;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('totalSales', 'desc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					Lifetime Revenue &#8593;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('totalSales', 'asc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					Arrival Dates &#8595;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('o_creationDate', 'desc')}
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>
			<li>
				<label className='container-radio-sort'>
					Arrival Dates &#8593;
					<input
						type='radio'
						name='sorting'
						onChange={props.sortProducts('o_creationDate', 'asc')} // Calling api in ascending order
					/>
					<span className='checkmark-radio-sort' />
				</label>
			</li>				
		</ul>			
  	);
}

export default SortProducts;