import React, { Component } from "react";
import { Input, Label } from "reactstrap";
import Select from '../UI Elements/Select'
import ct from "countries-and-timezones"

class Form extends Component {
  state = {
    data: {},
    error: {},
    timezones: [],
    countries: []
  }

  handleSubmit = e => {
    if(e){
      e.preventDefault()
      this.props.onLoadingChange(true)
      this.doSubmit()
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const {name, value} = input
    const data = { ...this.state.data }
    data[name] = value
        
    if (name === "company") {
        data['companyKey'] = this.generateCompanyKey(value)
        this.setState({ data }, () => this.companyKeyExists())
    }
    else
      this.setState({ data }, () => {
        if (name === "companyKey") 
            this.companyKeyExists()
      })
    this.setState({ data }, () => {
       if(name === 'country')
          this.getTimezone(input.value)
    })

    //generate company key
  }

  getTimezone =(country) => {
    let arr = [...Object.values(ct.getAllCountries()).filter(item => item.name === country)[0].timezones]
    let timezones = []
    for(let i=0; i < arr.length; i++){
        timezones.push({
           _id : i,
           name : arr[i]
        })  
    }
    let {data} = this.state
    if(timezones.length === 1)
        data['timeZone'] = timezones[0]
    this.setState({
       timezones,
       data : data   
    })
  }

  renderValidation = (inputType) => {
      const { error } = this.state

      if (error[inputType].length > 0) {
        return <div className='form__validation'>{error[inputType]}</div>
      } else return <div className='form__validation'>&nbsp;</div>
  }

  renderError(type){
     return(
      <label htmlFor='register-company' className='form__label'>
        <div className='row'>{this.renderValidation(type)}</div>
      </label>
     )
  }

  renderButton(label, name) {
    return (
      <button className={name}>
        {label}
      </button>
    );
  }

  renderSelect(name, label, options) {
    const { data } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
      />
    );
  }

  renderInput(name, label, type = "text") {
    const { data } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={name === 'companyKey' ? data[name].toUpperCase() : data[name]}
        label={label}
        onChange={this.handleChange}
        required
      />
    );
  }

  renderLabel(name){
     return(
        <Label>{name}</Label>
     )
  }
}

export default Form;