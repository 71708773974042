import React from 'react';
import {toast, Flip } from 'react-toastify';

const CustomToast = ({ toastProps, type, title, content }) => (
  <div>
      <h5>
        {type === 'error' ? <img src={require("./../assets/error-img.png")} alt="img"/> :  <img src={require("./../assets/active-img.png")} alt="img"/>}
        {title}
      </h5>
      <p>{content}</p>
  </div>
)

export function toastify(type, title, content){
  toast(<CustomToast type={type} title={title} content={content}/>, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
      })
  }

