import React, { useState } from 'react';
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import OrderExport from '../common/Exports/OrderExport';

const AddFields =({showAddFields, openAddFieldDiv, totalFields, selectedFields, handleCheckBox, loading, orders, fromDate, toDate, timeZone, companyKey, filters, orderKey, orderType, searchFilters}) => {

  const [modalOpen, setModal] = useState(false)

  const toggleModal = ()=> {
    setModal(!modalOpen)
  }

  const setFilters = (data) => {
    let filters = {};
    if(Object.keys(data).length > 0){  
      for (let [key, value] of Object.entries(data)) {
        let arr = [...value.split(",")];
        filters[key] = arr;
      }
      filters = {
        ...filters,
        ...searchFilters,
      }
    }
    return {...filters}
  }

  return (
    <div className='col-6 col-lg-3 orders__add-column-dropdown d-flex'>
      <div className={`p-1 dropdown-col ${loading ? 'loading' : ''} `}>
        {!loading && 
          <Dropdown
            isOpen={showAddFields}
            toggle={openAddFieldDiv}
          >
          <DropdownToggle className={`add-column ml-3 ${loading ? 'loading' : ''}`}>
              + Add column 
          </DropdownToggle>

          {/* Add-column drop-down */}
          <DropdownMenu
            id='add-field-div'
            style={{
              display: showAddFields ? "block" : "none",
            }}
          >
            {totalFields.map((item, key) => {
              return (
                <span key={key}>
                  <label className='custom-checkbox'>
                    {" "}
                    {item}
                    <input
                      defaultChecked={
                        selectedFields.includes(item) ? true : false
                      }
                      onChange={handleCheckBox}
                      type='checkbox'
                      name={item}
                    />
                    <span className='checkmark' />
                  </label>
                </span>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        }
      </div>
      <div className={`p-1 export-col ${loading ? 'loading' : ''} `}>
          {!loading && <button className='export' onClick={toggleModal} >
            <i className='fa fa-download p-2' aria-hidden='true' />
            Export</button>}
      </div>
      <OrderExport
          companyKey={companyKey}
          data={orders}
          filters={setFilters(filters)}
          fromDate={fromDate}
          modalOpen={modalOpen} 
          orderKey={orderKey}
          orderType={orderType}
          timeZone={timeZone}
          toDate={toDate}
          toggleModal={toggleModal}
      />
    </div>
  );
}

export default AddFields;