import http from './httpService'

const apiEndPoint = http.pim_app_url + '/report'

let prevReq = []

export async function getReportTypes(formData) {
  let call = http.CancelToken.source()
  prevReq.push(call)
  let res = await http.post(apiEndPoint + '/reportFeed', formData, {
    cancelToken: call.token
  })
  return res
}

export function getReports(formData) {
  return http.post(apiEndPoint+'/getReport', formData)
}

export function fetchReportData(url){
  var xhr = new XMLHttpRequest();

  return new Promise((resolve, reject) => {

    xhr.onreadystatechange = (e) => {
      if (xhr.readyState !== 4) {
        return;
      }

      if (xhr.status === 200) {
        let result = {
          data : xhr.responseText,
          ext: url.split('.').pop()
        }
        resolve(result);

      } else {
        console.warn('request_error');
      }
    };

    xhr.open('GET', url);
    xhr.send();
  });
}

export function cancelRequest(){
  while(prevReq.length > 0){
    prevReq.pop().cancel("operation canceled.")   
  }
}

