import React from 'react';
import ReactTooltip from 'react-tooltip';

function Inventory({stockCount, _id}) {

  const setInventoryUI = (item, key) => {
    if(isEmpty(item[1]))
      return "Not available"    
    return(
      <div className="inventory_count-div" key={key}>
        <h6>{item[0]}</h6>
        <div className="d-flex flex-wrap">
          {Object.entries(item[1]).map((inv, keyII)=>{              
            return(
              <div className="p-1" key={keyII}>
                <div className={`count-div ${inv[1] === 0 ? 'zero' : ''}`}>
                  <div className="label">{inv[0]}</div>  
                  {!inv[1] && <div className="cross"></div>}                    
                </div>
                {inv[1] && 
                <div className="value">
                  {inv[1]} Left
                </div>}
              </div>
            );
           })}
        </div>
      </div>
    );
  }

  const isEmpty = (item) => {
     if(Object.keys(item).length > 0)
        return false
     return true
  }

  if(!stockCount)
    return null

  return (
    <ReactTooltip id={'inventory_'+_id} aria-haspopup='true' backgroundColor='#f5f5f5' clickable={true}>
			  <div className="inventory_tooltip-div">
								<h5>Inventory</h5>
                {Object.entries(stockCount).map((item, key) => {
                   return setInventoryUI(item, key)
                })}
				</div>
		</ReactTooltip>		
  );
}

export default Inventory;