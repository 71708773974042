import React, { Component } from "react";
import Reports from "./Reports/Reports";
import Header from "../common/Header";
import Loading from "../common/Loading";
import { getActiveCompanyInfo } from "../../services/authService";

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      timeZone: ''
    }
  }

  componentDidMount() {
    this.setState({ 
        timeZone: getActiveCompanyInfo(this.props.companyKey)['Time Zone'],
        loading : false
     })
     
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.fromDate != this.props.fromDate || prevProps.toDate != this.props.toDate){
      this.setState({ 
          loading : false
       })
    }
    if(prevProps.companyKey !== this.props.companyKey){
      this.setState({ 
          timeZone: getActiveCompanyInfo(this.props.companyKey)['Time Zone'],
          loading : false
       })
    }
  }
  render() {
    const {timeZone, loading} = this.state
    if(loading === true)
        return(<Loading />)

    return (
      <div className='container-fluid analytics'>
          <div className='container-fluid analytics-header'>
              <Header
                title='Analytics'
                secondaryText={""}
              />
          </div>
          <Reports 
              companyKey={this.props.companyKey}
              fromDate={this.props.fromDate}
              toDate={this.props.toDate}
              timeZone={timeZone}
          />
      </div>
    );
  }
}

export default Analytics;
