import React from 'react';
import CreatePortal from './Create-Portal/CreatePortal';
import UpdatePortal from './Update-Portal/UpdatePortal';

function PortalContainer({selectedImport, companyKey, csvData, setCsvData}) {
  return (
    <div className="portal-container">
        {selectedImport === 'Create Products' ? 
          <CreatePortal companyKey={companyKey} csvData={csvData} setCsvData={setCsvData}/>
          :
          <UpdatePortal 
            companyKey={companyKey}
            csvData={csvData}
            selectedImport={selectedImport} 
            setCsvData={setCsvData}
        />
        }
    </div>
  );
}

export default PortalContainer;