import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class SettingsNav extends Component {
  render() {
    return (
      <div className='settings-nav'>
        <div className='settins-configure'>
          <h2 className='settings-heading-secondary pb-4'>
            Configure Settings
          </h2>
          <NavLink
            exact
            to='/settings/data_sources'
            className='settings-link'
            activeClassName='is-active'
          >
            Data Sources
          </NavLink>
          <NavLink
            exact
            to='/mappingPortal'
            className='settings-link'
            activeClassName='is-active'
          >
            Product Custom Upload
          </NavLink>
        </div>
        <div className='settings-account'>
          <h2 className='settings-heading-secondary pb-4'>Account Settings</h2>
          <NavLink
            to='/settings/account'
            className='settings-link'
            activeClassName='is-active'
          >
            Your Account
          </NavLink>
          <NavLink to='#' className='settings-link' activeClassName='is-active'>
            Current Plan
            <span className='pl-4 clickable'>Upgrade</span>
          </NavLink>
          <NavLink
            to='/settings/users'
            className='settings-link'
            activeClassName='is-active'
          >
            Active Users
          </NavLink>
        </div>
      </div>
    );
  }
}

export default SettingsNav;
