import React from 'react';

function Table({ children}) {
  return (
    <table className="table table-borderless data-grid-table">
       {children}
    </table>
  );
}

export default Table;