import React from 'react';
import CircProgressBar from './Circ-ProgressBar';

function DataPoint({customClass='', text='', currencySymbol, id, loadingDataPoints, value, label, tooltip, progress}) {
  return (
    <div className={`p-1 mr-2 divs ${loadingDataPoints ? 'loading' : customClass}`}
      data-tip 
      data-for={tooltip}
    >
        {!loadingDataPoints && 
        <React.Fragment>
            {label && label}
            <h6 className='mt-1'>
              {text && text}
              {currencySymbol && currencySymbol}
              {progress ?
                <CircProgressBar perc={value}/>
                :
                value
              }
              
            </h6>
        </React.Fragment> }
    </div>
  );
}

export default DataPoint;