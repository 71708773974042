import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { saveProductPosition } from '../../../../services/productService';

function ProductImage({enlargeImage, product}) {
  return (
    <div className='col-4 col-md-4 col-lg-3 image-div' 
      onMouseOver={() =>enlargeImage && enlargeImage(product.image_url)} 
      onMouseLeave={() => enlargeImage && enlargeImage('')}
      >
        <Link
          className='product-a'
          to={`/products/analytics/${product.baseKey}`}
          params={{ type: product.baseKey }}
          onClick={() =>saveProductPosition(product.position)}
        >
            <Image
              src={product.image_url}
              alt='Card image cap'
              className='products-img'
            />
        </Link>
    </div>
  );
}

export default ProductImage;