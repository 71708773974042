import React, { useState, useEffect } from 'react';
import { Row } from '../common/Row';

function ResolverSetting({ companyKey, csvData, pimFields, importType: importOptions, setData}) {
    const [resolverOptions, setResolvers ] = useState([])
    const [resolverSetting, setResolverSetting] = useState('')
    const [mappingAttr, setMappingAttr] = useState('')
    const [column, setColumn] = useState([]);
    const [keyColumn, setKeyColumn] = useState(null);
    const [otherAttributeOptions, setOtherAttributeOptions] = useState(null)
    const [showOtherAttr, setShowOtherAttr] = useState(false)
    const [importType, setImportType] = useState(importOptions[0])

    useEffect(() => {
        const data = pimFields;
        if(data){
            setColumn(csvData[0])
            setOtherAttributeOptions(Object.keys(importType.value))
            setResolvers(importType.mappingStrategy)
        }
    }, [pimFields, companyKey])

    const setResolverOptions = (importType) => {
        setImportType(importType)
        setResolvers(importType.mappingStrategy)
        setOtherAttributeOptions(Object.keys(importType.value))
    }

    const setMappingStrategy = (value) => {
        setResolverSetting(value)
        setMappingAttr(value)
    }

    const handleResolverSettingChange = (resolverSetting) => {
        setResolverSetting(resolverSetting.value);
        if (resolverSetting.value === 'Sheet Attributes') {
            setShowOtherAttr(true);
        } else {
            setShowOtherAttr(false);
        }
    }

    const handleKeyColumn = (val) => {
        setKeyColumn(val)
        sendFinalData(val)
    }

    const sendFinalData = (key)=> {
        // let csvData = getCsvData()
        let data = []
        let headers = [...csvData[0]]

        for(let i=1; i < csvData.length; i++){
            let obj = {}
            obj['key'] = csvData[i][headers.indexOf(key)]
            for(let j=0; j < headers.length; j++){
                obj[headers[j]] = csvData[i][j]
            }
            data.push(obj)
        }
        
        const finalData = {
            class: importType.value,
            companyKey: companyKey,
            strategy: resolverSetting,
            data: data
        }
        setData(finalData)
    }

    return (
        <div className="container-fluid resolver-settings">
            <div className="row">
                <Row 
                    title = {'Import type'}
                    options ={importOptions}
                    onChange={setResolverOptions}
                    selectedItem={importType}
                />
                <Row 
                    title = {'Mapping Strategy'}
                    options ={resolverOptions}
                    onChange={handleResolverSettingChange}
                    selectedItem={resolverSetting}
                />
                { showOtherAttr && 
                    <Row 
                        title = {'Mapping Attributes'}
                        options ={otherAttributeOptions}
                        onChange={setMappingStrategy}
                        selectedItem={mappingAttr}
                    />
                }
                <Row 
                    title = {'Sheet Columns'}
                    options ={column}
                    onChange={handleKeyColumn}
                    selectedItem={keyColumn}
                />
            </div> 
        </div>
    );
}

export default ResolverSetting;