import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import Loading from '../../common/Loading';

class LineGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            colors : ['#A396DB','#e91e63','#03a9f4', '#ff6054', '#ff9800', '#80e5b8']
        }
    }

	setDateType = (dateType) => () => {
		this.props.setDateType(dateType);
    }

    render() {
        const {colors} = this.state
        const {data, dateArray, dateType, selectedMPArr, loading} = this.props

        if(loading)
            return (<Loading />)

        return (
            <div className={`container-fluid lineGraph`}>
                {!loading && <div className="row lineGraph_row">
                    {/* legends for selected marketplaces */}
                    <div className="col-6 col-lg-8 show-legends d-flex flex-row">
                        {selectedMPArr.map((item, key) => {
                            return(
                                <div className="legends p-1 d-flex" key={key}>
                                    <div className="circle p-1" style={{background: colors[key]}}></div>
                                    <div className="label p-1">{item}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="abc">
                        {/* date array */}
                        <div className="d-flex flex-fill filter-type">
                            {dateArray.map((item, key) => {
                                return(
                                    <div className={dateType === item ? 'active' : '' } onClick={this.setDateType(item)} key={key}>
                                        <span>{item}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="chart-div">
                        <Chart width='120%' height={window.innerWidth > 576 ? '245px' : '300'}   chartType="Bar" data={data} 
                          options= {{
                            legend: { position: 'none'},
                            bars: 'vertical',
                            // fontSize: '5',
                            colors: colors,
                            chartArea: {
                                    height: '90%',
                                    width: '75%',
                                    left: '30px',
                            },
                            lineWidth: 3,
                            hAxis :{
                                baselineColor : '#9e9e9e',
                                format: 'MMM dd',
                                title: null,
                                // showTextEvery: data.length > 30 ? 2 : data.length <= 6 ? 1 : 5 ,
                                gridlines: {
                                    color : 'none',
                                    minorGridlines : {
                                        count : 0
                                    }
                                },
                                textStyle : { 
                                    color: '#9e9e9e',
                                    fontSize: 10,
                                },
                            },
                            animation : {
                                startup : true,
                                duration: 500,
                                easing : 'inAndOut'
                            },  
                            vAxis :{
                                baselineColor : '#9e9e9e',
                                format: 'short',
                                gridlines: { 
                                    count: 4,
                                    color : '#e8e5f4',
                                    minorGridlines : {
                                        count : 0
                                    }
                                },
                                textStyle : { 
                                    color: '#9e9e9e',
                                    fontSize: 5,
                                },
                            },
                            // series: {
                            //     1: { curveType: 'function' },
                            // }
                          }}
                          rootProps={{ 'data-testid': '3' }}/>
                    </div>			
				</div>}
			</div>
		);
	}
}
export default LineGraph;
