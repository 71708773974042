import React from 'react';
import Products from '../../assets/product-1.png'
import Customers from '../../assets/audience.png'
import Orders from '../../assets/order-1.png'
import { Image } from 'react-bootstrap';

function HomePage({onImportSelect}) {
  const options = ['Products', 'Orders', 'Customers']

  const setImage = (item) => {
     if(item === "Products")
        return Products
     else if(item === "Orders")
        return Orders
     else if(item === "Customers")
        return Customers
  }
  
  return (
    <div className="container-fluid import-selection">
        <div className="container-fluid center">
            <div className="row import-row row1">
              <h4>Update your data.</h4>
              <div className="col-12 d-flex justify-content-around">
                  {options.map((item)=> {
                    return(
                          <div className="p-2 row-item" onClick={() => onImportSelect(item)}>
                              <Image src={setImage(item)} />
                              <label>{item}</label>
                          </div>
                    )
                  })}
              </div>
            </div>
            <div className="row import-row row2">
                <h4 onClick={() => onImportSelect('Create Products')}>
                  <Image src={require('../../assets/plus-icon.png')} />
                  Create Products at base level.</h4>
            </div>
        </div>
    </div>
  );
}

export default HomePage;