import React from 'react';
import { Image } from 'react-bootstrap';

function LoadingAimation({message, page}) {
  return (
    <div className="loading-animation-div">
      <div className="loading-animation">
          <div class="loader"></div>

          {page === 'login' ?<Image src={require('../../assets/unlock.png')} /> : null}
          <h5>{message}</h5>
      </div>
    </div>
  );
}

export default LoadingAimation;