import http from "./httpService";

const apiEndPoint = http.pim_app_url + "/notification";

export async function getAllNotifications(formData) {
  const res = await http.post(apiEndPoint+'/getNotifications', formData)
  if(res.data.result === 'success')
      return res.data.processes
  return null
}

export async function getAllNotificationsII(formData) {
  const res = await http.post(apiEndPoint+'/getProcessTypes', formData)
  if(res.data.result === 'success')
      return res.data.processes
  return null
}

export async function getProductNotifications(formData) {
  const res = await http.post(http.pim_app_url+'/report/getAnalytics', formData)
  if(res.data.result === 'success')
      return res.data.result
  return null
}

export async function getOrderNotifications(formData) {
  const res = await http.post(http.pim_app_url+'/report/exportOrders', formData)
  if(res.data.result === 'success')
      return res.data.result
  return null
}

export async function editNotifications(formData) {
  const res = await http.post(apiEndPoint+'/updateNotification', formData)
  if(res.data.result === 'success')
      return res.data.result
  return null
}
