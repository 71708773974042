import React, {useState, useEffect} from 'react';
import { Chart } from 'react-google-charts';
import Loading from '../../common/Loading';
import SingleSelectionDropdown from '../../UI Elements/SingleSelectionDropdown';
import OrdersList from './OrdersList';

const countryCodes = require('../../../assets/countryISOCode.json')

function MapWidget({data : mapData, currencySymbol, loading}) {
    const [selectedCountry, setSelected] = useState({})
    const [countryArr, setCountryArr] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        let temp_arr = []

        if(mapData){
            for(let [key, value] of Object.entries(mapData)){
                let states = []
                let totalSales = 0
                let totalOrders = 0
    
                if(key.length > 0 && countryCodes.filter( item => item.country === key )[0])
                {
                    for(let [key2, value2] of Object.entries(value)){
                            states.push({
                                place: key2,
                                orders: value2.orders,
                                sales: Math.round(value2.sales).toFixed(2),
                            })
                            totalOrders+=value2.orders
                            totalSales+=value2.sales
                    }
    
                    states = states.sort(function (a, b) {
                        return a.sales - b.sales;
                    })
                    states = states.reverse()
                    
                    temp_arr.push({
                        name: key,
                        ISOCode: countryCodes.filter( item => item.country === key )[0]['ISO Code'],
                        places: [...states],
                        totalSales: Math.round(totalSales).toFixed(2),
                        totalOrders: Math.round(totalOrders).toFixed(2)
                    })
                }
            }
            
            temp_arr = temp_arr.sort(function (a, b) {
                return a.totalSales - b.totalSales;
            })
            temp_arr = temp_arr.reverse()
    
            if(temp_arr.length > 0){
                setCountryArr([...temp_arr])
                setSelected({...temp_arr[0]})
                setMapData(temp_arr[0])
            }
        }        
    }, [loading, mapData ])

    const changeCountry = (childData) => {
        setSelected({...childData})
        setMapData(childData)
    }
    const setMapData = (obj) => {
        let selectedCountryData = {...obj}
        let finalData = new Array(selectedCountryData.places.length +1)

        for(let i=1; i < finalData.length; i++){
            finalData[i] = new Array(2)
        }

        finalData[0] = ['Provinces', 'Orders']

        for(let i=1; i <finalData.length; i++){
            finalData[i] = [selectedCountryData.places[i-1].place, selectedCountryData.places[i-1].orders]
        }

        setData([...finalData])

    }

    if(!loading && data.length === 0)
        return <p className="not-available-msg">No Data Available</p>
    return (
        <div className="widget-container p-3">
            <div className={`widget-container_1 ${loading ? 'active': ''}`}>
                <h5>Orders by States</h5>
                <div className={`loading ${loading ? 'active': ''}`}></div>
                {!loading && 
                    <SingleSelectionDropdown 
                        data={countryArr} 
                        handleCheckbox={changeCountry} 
                        selectedValue={selectedCountry.name} 
                    />
                }
            </div>
            <div className="container-fluid mt-2 widget-container_2">
                <div className="row">
                    <div className="col-12 col-lg-6 map">
                        {loading ? <Loading top={'90px'} left={'190px'}/>
                            :
                            <Chart
                                width={'100%'}
                                height={'375px'}
                                chartType="GeoChart"
                                data={data}
                                options={{
                                    region: selectedCountry.ISOCode,
                                    resolution: 'provinces',
                                    colorAxis: {colors: ['#DED8FC', 'rgb(119, 103, 193)']},
                                    backgroundColor: 'white',
                                    datalessRegionColor: 'white',
                                    defaultColor: 'white',
                                }}
                                mapsApiKey="AIzaSyAcDa9j_O0uuskCA_XqnNYN3JwkldDtUQ0"
                                rootProps={{ 'data-testid': '1' }}
                            />
                        }
                    </div>
                    <div className="col-12 col-lg-6 pt-3 map-data">
                       <OrdersList
                            currencySymbol={currencySymbol}
                            selectedCountry={selectedCountry}
                            loading={loading}
                        />
                    </div>
                </div>        
            </div>
            
        </div>
    );
}

export default MapWidget;