import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import BarGraph from "../../../Graphs/BarGraphs/BarGraph";
import Cards from "../../../Graphs/Cards/Cards";
import DoughnutChart from "../../../Graphs/DoughnutChart/DoughnutChart";
import ErrorPage from "../../../common/ErrorPage";
import GraphTabs from "../../../Graphs/BarGraphs/GraphTabs";
import GoogleAnalytics from "./GoogleAnalytics";
import { getUser } from "../../../../services/authService";
import Header from "../../../common/Header";
import Loading from "../../../common/Loading";
import Orders from "./Orders";
import ProductIntro from "./ProductIntro";
import SingleSelectionDropdown from "../../../UI Elements/SingleSelectionDropdown";
import SimilarProducts from "./SimilarProducts";
import Variants from "./Variants";
import {
  getProductInfo,
  getProductDataPoints,
  getProductPosition,
  getProductSearchKeyword,
  getProductFilters,
  getProductSorting,
  saveProductPosition,
  getProductCount,
  cancelRequest,
} from "../../../../services/productService";
// import Maintainence from "../../../common/Maintainence";

class ProductMainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseSku: "",
      vitalInfoObject: {},
      links: {},
      variants: [],
      selectedMarketplace: "Shopify",
      score: null,
      dataPoints: {},
      lineData: {},
      cardsColor: [
        "#e91e63",
        "#7767c1",
        "#03a9f4",
        "#ff6054",
        "#ff9800",
        "#80e5b8",
        "#e91e63",
        "#7767c1",
        "#03a9f4",
        "#ff6054",
        "#ff9800",
        "#80e5b8",
      ],
      barData: [],
      variantBarData: [],
      selectedBarData: {},
      selectedVariantBarData: {},
      doughnutData: {},
      lineGraphHeight: 77,
      marketplaces: [
        {
          name: "Amazon",
          checked: false,
        },
        {
          name: "Ebay",
          checked: false,
        },
        {
          name: "Shopify",
          checked: false,
        },
        {
          name: "Walmart",
          checked: false,
        },
      ],
      dateArray: [],
      dateType: "date",
      loading: true,
      loadingLineGraph: false, //initially true
      loadingDS: true,
      error: false,
      productOrders: [],
      mainImage: null,
      graph: {},
      cardsData: {},
      googleData: {},
      similarProducts: [],
      prevProduct: "",
      nextProduct: "",
      isLeftBtnDisabled: true,
      isRightBtnDisabled: false,
    };
  }
  componentDidMount() {
    window.addEventListener("keydown", (event) => this.handleKeyPress(event));
    this.getProductInfo();
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", (event) =>
      this.handleKeyPress(event)
    );
    cancelRequest();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.fromDate !== this.props.fromDate ||
      prevProps.toDate !== this.props.toDate
    ) {
      this.setState(
        {
          loadingDS: true,
          loadingLineGraph: true,
        },
        () => this.fetchDataPoints()
      );
    }
    if (prevProps.sku !== this.props.sku) {
      this.setState(
        {
          loading: true,
          loadingDS: true,
          loadingLineGraph: true,
        },
        () => this.getProductInfo()
      );
    }
  }
  getProductInfo = async () => {
    try {
      const formData = {
        companyKey: this.props.companyKey,
        productSku: this.props.sku,
        email: getUser().userEmail,
        position: getProductPosition(),
        search: getProductSearchKeyword(),
        filters: getProductFilters(),
        orderKey: getProductSorting().sortKey,
        orderType: getProductSorting().sortType,
      };

      console.log(JSON.stringify(formData));

      const res = await getProductInfo(formData);
      console.log(res.data);
      const { variants, images, links, attributes, data } = res.data.product;
      let arr = [...variants];
      let variantArr = [];
      for (let i = 0; i < arr.length; i++) {
        variantArr.push({
          sku: arr[i].attributes.sku.value,
          archived: arr[i].attributes.archived.value,
          price: arr[i].attributes.price.value,
          imgUrl: images ? images[i] : null,
          inventory: arr[i].attributes.quantity.value,
          color: arr[i].attributes.color.value,
          size: arr[i].attributes.size.value,
          shape: arr[i].attributes.shape.value,
          orderQty: 0,
          doi: 0,
          ros: 0,
        });
      }
      this.setState(
        {
          baseSku: attributes.baseSku.value,
          similarProducts: res.data.product["Similar products baseSKU"],
          vitalInfoObject: attributes,
          links: links,
          variants: variantArr,
          images: images ? images : [],
          mainImage: images ? images[0] : null,
          loading: false,
          prevProduct: data ? data.prev : "",
          nextProduct: data ? data.next : "",
        },
        () => {
          this.fetchDataPoints();
        }
      );
    } catch (ex) {
      console.log(ex);
      if (!ex.message === "operation cancelled") this.setState({ error: true });
    }
  };
  fetchDataPoints = async () => {
    try {
      const { dateType, baseSku } = this.state;
      const { fromDate, toDate } = this.props;
      const formData = {
        toDate: toDate,
        fromDate: fromDate,
        companyKey: this.props.companyKey,
        timeZone: this.props.companyInfo["Time Zone"],
        baseSku: [baseSku],
        step: dateType,
        chartTypes: {
          PIE: {
            PIE_MARKETPLACE_ORDERS: {},
            PIE_MARKETPLACE_ORDER_SALES: {},
            PIE_MARKETPLACE_RETURN_SALES: {},
            PIE_MARKETPLACE_RETURNS: {},
          },
          BAR: {
            BAR_B_ORDERS_DAYWISE: {},
            BAR_C_ORDERS_COLORWISE: {},
            BAR_A_ORDERS_SIZEWISE: {},
            BAR_A_STOCK_SIZEWISE: {},
            BAR_B_STOCK_COLORWISE: {},
          },
          LINE: {
            LINE_ORDERS: {},
            LINE_ORDER_SALES: {},
            LINE_RETURN_ORDERS: {},
            LINE_RETURN_ORDER_SALES: {},
          },
          WHEEL: {
            Brands: {},
            "Product Categories": {},
            "Product Types": {},
            "MarketPlace Sales": {},
          },
          CARD: [
            "CARD_PRODUCTS_COUNT",
            "CARD_VARIANTS_COUNT",
            "CARD_NET_PROFIT",
            "CARD_PROFIT_MARGIN",
            "CARD_TOTAL_SALES",
            "CARD_AVG_SELLING_PRICE",
            "CARD_AVG_REVENUE_PERDAY",
            "CARD_TOTAL_ORDERS",
            "CARD_PREV_TOTAL_ORDERS",
            "CARD_PREV_NET_PROFIT",
            "CARD_PREV_PROFIT_MARGIN",
            "CARD_PREV_TOTAL_SALES",
            "CARD_PREV_AVG_SELLING_PRICE",
            "CARD_PREV_AVG_REVENUE_PERDAY",
            "CARD_NET_SALES",
            "CARD_TOTAL_RETURN_SALES",
            "CARD_TOTAL_RETURN",
            "CARD_QUANTITY_UNIT",
          ],
          LIST: [
            "LIST_ORDERS_STATEWISE",
            "LIST_TOP_SKUS",
            "LIST_TOP_TRENDING",
            "LIST_BIGGEST_LOSER",
            "LIST_SLOWEST_MOVING",
            "LIST_GOOGLE_ANALYTICS",
          ],
        },
      };
      // console.log(JSON.stringify(formData))
      const res = await getProductDataPoints(formData);
      console.log(res.data);

      let productData = res.data[this.state.baseSku];

      this.setOrders([...productData["orders"]]);
      this.setVariants({ ...productData["variant Wise Orders"] });

      this.setState(
        {
          dataPoints: { ...productData },
          lineData: productData["chartData"]["LINE"],
          cardsData: productData["chartData"]["CARD"],
          googleData: productData["chartData"]["GA"],
          graph: productData["chartData"]["CARD"]["CARD_TOTAL_SALES"],
          doughnutData: productData["chartData"]["WHEEL"]["MarketPlace Sales"],
          score: productData["Score"],
          dateArray: productData["chartData"]["LINE"]["LINE_ORDERS"]["steps"],
          loadingDS: false,
          loadingLineGraph: false,
        },
        () => {
          this.setBarData(productData["chartData"]["BAR"]);
        }
      );
    } catch (ex) {
      if (!ex.message === "operation cancelled") this.setState({ error: true });
    }
  };
  setOrders = (arr) => {
    let orders = [];
    for (let i = 0; i < arr.length; i++) {
      orders.push({
        sku: arr[i].sku,
        orderNo: arr[i].orderNo,
        orderDate: arr[i].orderDate,
        price: arr[i].price,
        quantity: arr[i].quantity,
        marketplace: arr[i].marketplace,
      });
    }
    this.setState({ productOrders: orders });
  };
  setVariants = (obj) => {
    let variants = [...this.state.variants];
    if (Object.keys(obj).length > 0) {
      let keys = Object.keys(obj);
      for (let i = 0; i < variants.length; i++) {
        if (keys.includes(variants[i].sku)) {
          variants[i].orderQty = obj[variants[i].sku].order;
          variants[i].inventory = obj[variants[i].sku].quantity;
          variants[i].doi = obj[variants[i].sku].doi;
          variants[i].ros = obj[variants[i].sku].ros;
        } else {
          variants[i].orderQty = 0;
        }
      }
      this.setState({ variants });
    }
  };
  setDateFormat = (d1) => {
    let date = d1.getDate();
    let month = d1.getMonth() + 1;
    let year = d1.getFullYear();

    let month2 = month.toString().length === 1 ? "0" + month : month;

    let finalStr = date + "-" + month2 + "-" + year;
    return finalStr;
  };
  setDateType = (childData) => {
    this.setState(
      {
        dateType: childData,
        loadingLineGraph: true,
      },
      () => this.fetchDataPoints()
    );
  };
  setBarData = (obj) => {
    let obj1 = { ...obj["Orders"] };
    let obj2 = { ...obj["Variant"] };
    let barData = [];

    for (let [key, value] of Object.entries(obj1)) {
      let temp_obj = {};
      if (Object.keys(value).length > 0) {
        let name = "";
        if (key === "BAR_C_ORDERS_COLORWISE") {
          name = "Colors";
        } else if (key === "BAR_B_ORDERS_DAYWISE") {
          name = "Weekly";
        } else if (key === "BAR_A_ORDERS_SIZEWISE") {
          name = "Sizes";
        }
        temp_obj["labels"] = [...Object.keys(value)];

        let arr = [];
        for (let [key2, value2] of Object.entries(value)) {
          arr.push(value2);
        }
        temp_obj["values"] = [...arr];
        barData.push({
          name: name,
          data: temp_obj,
        });
      }
    }

    let barData2 = [];

    for (let [key, value] of Object.entries(obj2)) {
      let temp_obj = {};
      if (Object.keys(value).length > 0) {
        let name = "";
        if (key === "BAR_A_STOCK_COLORWISE") {
          name = "Colors";
        } else if (key === "BAR_A_STOCK_SIZEWISE") {
          name = "Sizes";
        }
        temp_obj["labels"] = [...Object.keys(value)];

        let arr = [];
        for (let [key2, value2] of Object.entries(value)) {
          arr.push(value2.quantity);
        }
        temp_obj["values"] = [...arr];
        barData2.push({
          name: name,
          data: temp_obj,
        });
      }
    }
    this.setState({
      barData: barData,
      variantBarData: barData2,
      selectedBarData: barData[0],
      selectedVariantBarData: barData2[0],
    });
  };
  handleBarDataSelection = (childData, type) => {
    if (type === "orders")
      this.setState({
        selectedBarData: childData,
      });
    else {
      this.setState({
        selectedVariantBarData: childData,
      });
    }
  };
  openGraph = (graph) => {
    this.setState({
      graph,
    });
  };
  handleKeyPress = (event) => {
    if (event.key === "j") {
      saveProductPosition(parseInt(getProductPosition()) - 1);
      window.location = "/products/analytics/" + this.state.prevProduct;
    } else if (event.key === "k") {
      saveProductPosition(parseInt(getProductPosition()) + 1);
      window.location = "/products/analytics/" + this.state.nextProduct;
    }
  };
  render() {
    const {
      vitalInfoObject,
      links,
      baseSku,
      variants,
      images,
      score,
      dataPoints,
      lineData,
      cardsColor,
      barData,
      selectedBarData,
      doughnutData,
      dateArray,
      dateType,
      loading,
      loadingDS,
      productOrders,
      mainImage,
      loadingLineGraph,
      error,
      graph,
      cardsData,
      googleData,
      variantBarData,
      selectedVariantBarData,
      similarProducts,
      prevProduct,
      nextProduct,
    } = this.state;

    const { fromDate, toDate, companyKey, companyInfo } = this.props;

    const currencySymbol = this.props.companyInfo["Currency Symbol"];
    if (error === true) return <ErrorPage errorType={"server"} />;
    else if (loading === true)
      return <Loading message={"Loading your data..."} />;
    else
      return (
        <div className="container-fluid main-product-page">
          <Header title="Products" secondaryText={baseSku} />
          {/* link to go to previous product */}
          <div className="row row0 pl-3">
            {getProductPosition() > 1 ? (
              <Link
                className="prev-product-a"
                to={`/products/analytics/${prevProduct}`}
                params={{ type: prevProduct }}
                onClick={() =>
                  saveProductPosition(parseInt(getProductPosition()) - 1)
                }
              >
                <button>
                  <FontAwesomeIcon className="left-icon" icon={faArrowLeft} />
                </button>
              </Link>
            ) : null}

            {/* link to go to next product */}
            {getProductPosition() < parseInt(getProductCount()) ? (
              <Link
                className="next-product-a"
                to={`/products/analytics/${nextProduct}`}
                params={{ type: nextProduct }}
                onClick={() =>
                  saveProductPosition(parseInt(getProductPosition()) + 1)
                }
              >
                <button>
                  <FontAwesomeIcon className="right-icon" icon={faArrowRight} />
                </button>
              </Link>
            ) : null}
          </div>
          <div className="row row1 pl-3">
            <div className="col-12 col-lg-8">
              <ProductIntro
                currencySymbol={currencySymbol}
                dataPoints={dataPoints}
                images={images}
                links={links}
                loadingStatus={loadingDS}
                score={score}
                vitalInfoObject={vitalInfoObject}
              />
            </div>
            <div className="col-12 col-lg-4">
              <GoogleAnalytics />
            </div>
          </div>

          <div className="row row2">
            <Cards
              data={cardsData}
              lineData={lineData}
              loadingDS={loadingDS}
              currencySymbol={currencySymbol}
              openGraph={this.openGraph}
            />
          </div>

          <div className="row row3">
            <div className="col-12 col-lg-8 product-line-graph pl-1">
              <div className="line-graphs">
                {loadingLineGraph === true ? (
                  <Loading />
                ) : (
                  <GraphTabs
                    setDateType={this.setDateTypeFn}
                    data={lineData}
                    dateType={dateType}
                    dateArray={dateArray}
                    fromDate={fromDate}
                    toDate={toDate}
                    currencySymbol={currencySymbol}
                    graph={graph}
                    loading={loadingLineGraph}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="marketplace-doughnut-chart">
                <h5>Sales Breakdown</h5>
                {/* {loadingDS === true ? (
                  <Loading />
                ) : ( */}
                  <DoughnutChart data={doughnutData} colors={cardsColor} />
                {/* )} */}
              </div>
            </div>
          </div>

          <div className="row row4">
            <div className="col-10 col-lg-6 p-0">
              <div className="variant-bar-graph">
                <h5>Orders</h5>
                {/* {loadingDS === true ? (
                  <Loading />
                ) :  */}
                {barData.length > 0 ? (
                  <React.Fragment>
                    <SingleSelectionDropdown
                      data={barData}
                      handleCheckbox={(data) =>
                        this.handleBarDataSelection(data, "orders")
                      }
                      selectedValue={selectedBarData.name}
                      right={"50px"}
                    />

                    <BarGraph data={selectedBarData.data} colors={cardsColor} />
                  </React.Fragment>
                ) : (
                  <p className="not-available-msg">No data Available</p>
                )}
              </div>
            </div>
            <div className="col-10 col-lg-6 p-0">
              <div className="conversion-data">
                <h5>Inventory</h5>
                {/* <GoogleAnalytics 
                                    data={googleData}
                                    loading={loadingDS}
                                /> */}
                {/* {loadingDS === true ? (
                  <Loading />
                ) :  */}
                {variantBarData.length > 0 ? (
                  <React.Fragment>
                    <SingleSelectionDropdown
                      data={variantBarData}
                      handleCheckbox={(data) =>
                        this.handleBarDataSelection(data, "variant")
                      }
                      selectedValue={selectedVariantBarData.name}
                      right={"70px"}
                    />

                    <BarGraph
                      data={selectedVariantBarData.data}
                      colors={cardsColor}
                    />
                  </React.Fragment>
                ) : (
                  <p className="not-available-msg">No data Available</p>
                )}
              </div>
            </div>
          </div>

          <div className="row row5">
            <Variants
              variants={variants}
              currencySymbol={currencySymbol}
              mainImage={mainImage}
              loadingStatus={loadingDS}
              companyKey={companyKey}
            />
          </div>

          <div className="row row6">
            <Orders
              productOrders={productOrders}
              currencySymbol={currencySymbol}
              loadingStatus={loadingDS}
            />
          </div>

          <div className="row row7">
            <SimilarProducts
              products={similarProducts}
              fromDate={fromDate}
              toDate={toDate}
              companyKey={companyKey}
              dateType={dateType}
              companyInfo={companyInfo}
            />
          </div>
        </div>
      );
  }
}

export default ProductMainPage;
