import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toastify } from "../../../common-functions/notify";
import { getUser } from "../../../services/authService";
import { cancelRequest, getReportTypes, getReports } from "../../../services/reportService";
import Loading from "../../common/Loading"

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: ["sales", "orders", "products", "inventory", "customers"],
      loading : true,
      reports : {},
      reportType : '',
      storeName : ''
    };
  }

  async componentDidMount() {
      const formData = {
         companyKey : this.props.companyKey
      }
      try{
        const res = await getReportTypes(formData)
        console.log(res.data)
        const {data, result} = res.data
        if(result === 'success'){
           this.setState({
              reports : {...data},
              loading : false
           })

        }

      }
      catch(ex){
         console.log(ex)
      }
  }

  async componentDidUpdate(prevProps, prevState){
      if(prevProps.companyKey !== this.props.companyKey){
        const formData = {
          companyKey : this.props.companyKey
       }
       try{
         const res = await getReportTypes(formData)
         console.log(res.data)
         const {data, result} = res.data
         if(result === 'success'){
            this.setState({
               reports : {...data},
               loading : false
            })
         }
 
       }
       catch(ex){
          console.log(ex)
       }
      }
  }

  componentWillUnmount(){
    cancelRequest()
  }

  onFileClicked = (params) => {
     let formData = {
        "companyKey":this.props.companyKey,
        "email": getUser().userEmail,
     }

     for(let [key, value] of Object.entries(params)){
        if(value)
            formData[key] = value 
        else if(key !== 'companyKey' && key !== 'email'){
           formData[key] = this.props[key]
        }         
     }
     toastify('success','Request Sent', 'Downloading will begin soon.')
     this.getData(formData)
  }

  getData = async (formData)=> {
      try{
        const res = await getReports(formData)
        console.log(res.data)
      }
      catch(ex){
         console.log(ex)
      }
  }

  renderReportCard(label, linksArr){
    return(
      <React.Fragment>
        <span className='section-reports__widget-header mt-4 pb-2'>
           {label}
        </span>
        <ul className='section-reports__widget-content mt-4'>
          {Object.entries(linksArr).map((item, key) => {
             return(
                <li className='clickable report-name' key={key}>
                  {" "}
                  {item[1].comingSoon=== true ? 
                    <button className="coming-soon" disabled={true}>
                        {item[0]}
                        <span>Coming Soon</span>
                    </button>
                    :
                    <button onClick={() =>this.onFileClicked(item[1].params)}>
                        {item[0]}
                    </button>
                  }
                </li>
             )
          })}
        </ul>
      </React.Fragment>
    )
  }

  render() {
    const { reports, loading } = this.state

    if(loading === true)
        return(<Loading message={'Loading'}/>)

    return (
      <section className='section-reports ml-4'>
        <div className='row section-reports__header'>
          <nav aria-label='breadcrumb' className='section-reports__breadcrumbs'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/analytics' className='breadcrumb-link'>
                  Analytics
                </Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                Reports
              </li>
            </ol>
          </nav>
        </div>

        <div className='row d-flex flex-row justify-content-center section-reports__cards'>
            <div className='section-reports__card ml-4'>
                {this.renderReportCard('Feeds', reports['Feeds'])}
            </div>
            <div className='section-reports__card ml-4'>
                {this.renderReportCard('Inventory', reports['Inventory'])}
                {this.renderReportCard('Analytics', reports['Analytics'])}            
            </div>
        </div>
      </section>
    );
  }
}

export default Reports;
