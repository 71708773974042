import React, { useEffect } from 'react';
import CheckBox from '../../../UI Elements/CheckBox';
import ScoreIcon from '../../ScoreIcon';
import Inventory from '../Tooltips/Inventory';
import StockOutProducts from '../Tooltips/StockOutProducts';
import Title from '../Tooltips/Title';
import Card from "react-bootstrap/Card";
import Container from './Container';
import ProductCard from './ProductCard';

function ProductList({products_array, loadingDataPoints, currencySymbol, enlargeImage, onProductSelect}) {

  useEffect( () => {},[products_array] );

  if(products_array.length === 0)
    return null;

  return (
    products_array.map((product, key) => {
      return (
        <ProductCard id={key} product={product} key={key}>
          <CheckBox 
            onCheckboxClick={onProductSelect}
            value={product.baseSku}
            checked={product.checked}
          />
          {!loadingDataPoints &&
            <ScoreIcon
              category={product["Product Category"]}
              scoreDetails={product['Score']}
              zoom = {'zoom_out'}
              _id={product.baseKey}
            />
          }
          <Card className='product-card'>
            <Container 
              product={product}
              enlargeImage={enlargeImage}
              loadingDataPoints={loadingDataPoints}
              onProductSelect={onProductSelect}
              id={product.baseSku}
              currencySymbol={currencySymbol}
            />
          </Card>
          {/* tooltips */}
          <Title />
          <StockOutProducts stockCount={product['Stock Count']} _id={product.baseKey}/>
          <Inventory stockCount={product['Stock Count']} _id={product.baseKey}/>
        </ProductCard>
      );
    })
  ); 
}

export default ProductList;