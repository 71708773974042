import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

const OrderItem = ({ item }) => {
  return (
    <div className='row order-item'>
      <div className='row order-item__column-headers pt-2'>
        <div className='col-0 col-md-1'></div>
        <div className='col-3 col-md-2'>
          <Image src={item.Image ? item.Image : require('../../../assets/no-image.png')} />
        </div>
        <div className='col-3 col-md-2 field pt-2' title={item.SKU}>
          <span>{item.SKU}</span>
        </div>
        <div className='col-3 col-md-2 field pt-2' title={item.Rate}>
          <span>{item.Rate}</span>
        </div>
        <div className='col-3 col-sm-3 field pt-2' title={item.Title}>
          <span>{item.Title}</span>
        </div>
      </div>
    </div>
  );
};

OrderItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default OrderItem;
