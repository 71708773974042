import React, { Component } from 'react';
import { Doughnut } from "react-chartjs-2"
import SingleSelectionDropdown from '../../UI Elements/SingleSelectionDropdown';
import { setNumbers } from '../../../common-functions/setNumbers';
import { Image } from 'react-bootstrap';
import LoadingContent from '../../common/LoadingContent';

class DoughnutChart extends Component {
    constructor(props){
        super(props)
        this.state = {
            data:  [],
            selectedData: [],
            backgroundColor: [],
            selectedProductData : {},
            chartTypes: [],
            activeTab: ''
        }
        this.selectedType = this.selectedType.bind(this)
    }
    selectedType =(item)=>() => {
        this.setState({
            selectedProductData : item,
            activeTab: item.type
        })
    }
    componentDidMount(){       
        this.setData()
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.loading !== this.props.loading || prevProps.data !== this.props.data)
            this.setData()
    }
    setData(){
        const {data : objs, colors} = this.props
        let temp_colors = []                   
        let finalData = [] 

        for(let [key, value] of Object.entries(objs)){
                                
            if(Object.keys(value).length > 1){ 
                let productData = [] 
                let arr = this.calculatePercent(value)
                let labels = []
                let arr2 = []
                let objKeys = [...Object.keys(value)]
                let percentArr = []

                if(arr.length > 5){
                    
                    arr2 = this.getMaximumValues(1, arr)
                    arr2 = [...this.sortArr(arr2)]  //sort the array in reverse order
                    
                    for(let i=0; i< arr2.length; i++){
                        percentArr.push(arr2[i].value)
                    }
                    for(let i=0; i < arr2.length -1; i++){
                        let index = arr2[i].index                  
                        labels.push(objKeys[index])
                    }
                    labels.push('Others')
                }
                else {
                    let newArr = []
                    for(let i=0; i < arr.length; i++){
                        newArr.push({
                            index: i,
                            value: arr[i]
                        })
                    }
                                        
                    newArr = newArr.sort(function(a, b){
                        return a['value']-b['value']
                    })
                    newArr = newArr.reverse()

                    for(let i=0; i < newArr.length; i++){
                        if(newArr[i].value > 0){
                            labels.push(objKeys[newArr[i].index])
                            percentArr.push(newArr[i].value)
                        }
                        else {
                            newArr.splice(i, 1)
                        }
                    }
                }
                
                if(key === 'Product Categories')
                    productData = this.setProductCategoryData(percentArr, labels, value)
                else
                    productData = this.setproductData(percentArr, labels, value)

                finalData.push({
                    name : key,
                    labels : [...labels],
                    data : [...percentArr],
                    productData : [...productData],
                    checked : finalData.length === 0 ? true : false
                })
            }
        }
    
        for(let i=0; i < colors.length; i++){
            temp_colors.push(colors[i].color)
        }
    
        this.setState({
            data : finalData,
            selectedData: (finalData[0]),
            selectedProductData: finalData[0] !== undefined || finalData.length > 0 ? finalData[0].productData[0] : {},
            colors : temp_colors,
            activeTab: finalData.length > 0 ? finalData[0].productData[0].type : ''
        })
    }
    sortArr(arr){
        let lastObj = arr[arr.length -1]

        arr.splice(arr.length-1, 1)
        // console.log(arr)

        let sortedArr = arr.sort(function(a, b) {
            return  a['value'] - b['value']
        })
        sortedArr = sortedArr.reverse()
        sortedArr = [...sortedArr, {...lastObj}]
        return sortedArr
    }
    calculatePercent(obj){
        let sum =0 
        let percentArray = []
        // get sum             
        for(let [key, value] of Object.entries(obj)){
            sum += value['OrderPrice']
        }

        //get percent of each value
        for(let [key, value] of Object.entries(obj)){
            percentArray.push(Math.round((value['OrderPrice']/sum)*100))
        }
        return percentArray
    }
    getMaximumValues(percentCheck, arr){
        let temp_arr = []
        let sum =0
        // console.log(arr)
        
        for(let i=0; i < arr.length; i++){
            if(arr[i] > percentCheck){
                // console.log(i, arr[i])
                temp_arr.push({ index: i, value:arr[i]})
                sum += arr[i]
            }
        }

        if(temp_arr.length > 4){
            temp_arr.length = 0
            percentCheck += 1
            return this.getMaximumValues(percentCheck++, arr)
        }
       
        let finalArr = []
        finalArr = [...temp_arr, {index: temp_arr.length + 1, value: 100 - sum}] 
        // console.log(finalArr)
        // finalArr = this.sort(finalArr) 
        // console.log(finalArr)
        return finalArr         
        
    }
    setproductData(arr2, labels, value){
        const {colors} = this.props
        let productData = []

        let arrLength = 0
        if(labels[labels.length -1] === 'Others'){
            arrLength = labels.length - 1
        }
        else {
            arrLength = labels.length 
        }

        for(let i=0; i < arrLength ; i++){
            for(let[type, typeData] of Object.entries(value)){
                if(type === labels[i]){
                    productData.push({
                        type : labels[i],
                        percent : arr2[i],
                        sales: typeData['OrderPrice'],
                        activeProducts: typeData['ProductCount'],
                        totalOrders:typeData['OrderCount'],
                        stockout: 45,
                        broken: 2,
                        inventory: typeData['ProductQuantity'],
                        color: colors[i].color        
                    })
                }
            }          
        }

        if(labels[labels.length -1] === 'Others'){
            let orderPrice =0;
            let quantity = 0;
            let count = 0
            let totalOrders = 0

            for(let[type, typeData] of Object.entries(value)){            
                if(!labels.includes(type)){
                    orderPrice += typeData['OrderPrice']
                    quantity += typeData['ProductQuantity']
                    count += typeData['ProductCount']
                    totalOrders +=typeData['OrderCount']
                }
            }
            productData.push({
                type : 'Others',        
                percent : arr2[arr2.length -1],
                sales: orderPrice,
                activeProducts: count,
                totalOrders:totalOrders,
                stockout: 45,
                broken: 2,
                inventory: quantity,
                color: colors[4].color,
                background: colors[4].background
            })
        }

        return productData
    }
    setProductCategoryData(arr2, labels, value){
        const {colors} = this.props
        let productData = []

        let arrLength = arr2.length

        for(let i=0; i < arrLength ; i++){
            for(let[type, typeData] of Object.entries(value)){
                if(type === labels[i]){
                    productData.push({
                        type : labels[i],
                        img : this.setProductCategoryImage(labels[i]),
                        percent : arr2[i],
                        sales: typeData['OrderPrice'],
                        activeProducts: typeData['ProductCount'],
                        totalOrders:typeData['OrderCount'],
                        stockout: 45,
                        broken: 2,
                        inventory: typeData['ProductQuantity'],
                        color: colors[i].color,
                        background: colors[i].background        
                    })
                }
            }          
        }
        return productData
    }
    setProductCategoryImage(category){
        if(category === 'Seed')
            return 'seed.PNG'
        else if(category === 'Tree')
            return 'tree.PNG'
        else if(category === 'Sprout')
            return 'sprout.PNG'
        else
            return 'barren.PNG'
    }
    handleCheckbox = (childData) => {

        this.setState({
            selectedData : childData,
            selectedProductData : childData.productData[0],
        })
    }
    setHeight(){
        let width = window.innerWidth

        if(width > 576 & width <= 768){
            return '170px'
        }
        else if(width <= 576){
            return '130px'
        }
        else if(width > 1500){
            return '170%'
        }
        else 
            return '270px'
    }

    render() {
        const {selectedProductData, data, selectedData, colors, activeTab} = this.state
        const { loading, currencySymbol} = this.props

        if(data.length === 0 && !loading)
            return(<h3>No Data available</h3>)
        return (
            <div className="doughnut-chart">
                <div className="d-flex justify-content-between doughnut-header">
                    <div className="p-2 title">
                        Sales Breakdown
                    </div>
                    <div className="p-2">
                        {loading ? null
                            : data.length > 1 ?
                            <SingleSelectionDropdown 
                                data={data} 
                                handleCheckbox={this.handleCheckbox} 
                                selectedValue={selectedData.name }
                            /> : selectedData.name
                        }
                    </div>
                </div>
                    {!loading ?
                        <div className="row doughnut-body mt-3">
                            <div className="col-12 col-md-7">
                                {selectedData.productData.map((item,key)=>{
                                    return (
                                        <div 
                                            className={`d-flex doughnut-product-types ${activeTab === item.type ? 'active': null}`} 
                                            onClick={this.selectedType(item)}
                                            key={key}
                                            >                                    
                                            <div className="ml-1 p-1 flex-fill">
                                                <div className="color-box mt-1" style={{background : item.color}}></div>
                                            </div>
                                            <div className="p-1 flex-fill">
                                                {item.img && <Image src={require('../../../assets/'+item.img)} thumbnail/>}
                                                {item.type}
                                            </div>
                                            <div className="p-1 flex-fill">
                                                {item.percent}%
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="col-12 col-md-5">
                                <Doughnut 
                                    data={{
                                        datasets : [{
                                            data: [...selectedData.data],
                                            backgroundColor: [...colors]
                                        }],
                                        labels:[...selectedData.labels]
                                    }} 
                                    options = {{
                                        legend: false,
                                        animation :{
                                            animateScale : true,
                                        }
                                    }}
                                    height={this.setHeight()}
                                    />
                            </div>
                    
                        </div>
                        : <LoadingContent 
                            width={'300px'} 
                            marginTop={'10px'} 
                            height={'120px'} 
                            marginLeft={'10px'}
                            
                        />
                    }
                <div className={`d-flex justify-content-between doughnut-footer row2 mt-4 p-2 ${loading}`} >
                    <div className="p-1">
                        {loading ? 
                            <LoadingContent 
                                width={'70px'} 
                                marginTop={'10px'} 
                                height={'10px'} 
                                marginLeft={'10px'}
                                
                            />
                            :
                            <h5>{currencySymbol}{setNumbers(selectedProductData.sales)}</h5>
                        }
                        {loading ? 
                            <LoadingContent 
                                width={'60px'} 
                                marginTop={'10px'} 
                                height={'10px'} 
                                marginLeft={'10px'}
                                
                            />
                            :
                            <p>Total Sales</p>
                        }
                    </div>
                    <div className="p-1">
                    {loading ? 
                        <LoadingContent 
                            width={'70px'} 
                            marginTop={'10px'} 
                            height={'10px'} 
                            marginLeft={'10px'}
                            
                        />
                        :
                        <h5>{setNumbers(selectedProductData.totalOrders)}</h5>
                    }
                    {loading ? 
                            <LoadingContent 
                                width={'60px'} 
                                marginTop={'10px'} 
                                height={'10px'} 
                                marginLeft={'10px'}
                                
                            />
                            :
                        <p>Total Orders</p>
                    }
                    </div>
                </div>
                <div className={`d-flex justify-content-between doughnut-footer row1 p-2 ${loading}`}>
                    {!loading && selectedProductData.activeProducts !== undefined ?
                        <div className="p-2 ">
                            <h5>{setNumbers(selectedProductData.activeProducts)}</h5> 
                            <p>Active Products</p>
                        </div>
                        : null
                    }
                    {!loading && selectedProductData.inventory !== undefined ?
                        <div className="p-2">
                            <h5>{setNumbers(selectedProductData.inventory)} </h5>
                            <p>Inventory</p>
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }
}

export default DoughnutChart;