import React from 'react';
import { Image } from 'react-bootstrap';

function GoogleAnalytics() {  
//   if(loading)
//     return null

  return (
    <div className="google-analytics p-3">
        <Image src={require('../../../../assets/funnel-ui.gif')} />
        <h6 className="labels label-1">Page Views</h6>
        <h6 className="labels label-2">Added To Cart</h6>
        <h6 className="labels label-3">Checkout</h6>
        <h6 className="labels label-4">Conversions</h6>
    </div>
  );
}

export default GoogleAnalytics;