import React from 'react';
import Form from './form';

class ShopifyForm extends Form {
  constructor(props){
     super(props)
     this.state={
        data : {  companyKey : '', email: '', password: ''},
        error : {},
        formType : ''
     }
  }

  componentDidMount(){
    const {data: shopifyData} = this.props
    let {data} = this.state
    data['companyKey'] = shopifyData['companyKey']
    data['email'] = shopifyData['email']
    this.setState({data})
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.data !== this.props.data){
      const {data: shopifyData} = this.props
      let {data} = this.state
      data['companyKey'] = shopifyData['companyKey']
      data['email'] = shopifyData['email']
      this.setState({data})

    }
  }

  displayOptions =()=>{
     return(
       <div className="btn-div">
         <button className="shopify-btn" onClick={()=>this.setForm('new')}>Register With New Company</button>
         <button className="shopify-btn" onClick={()=>this.setForm('existing')}>Register With Existing Company</button>
       </div>
     )
  }

  setForm=(formType)=> {
    this.setState({formType : formType})
  }

  openForm(){
     const {formType} = this.state

     if(formType === 'new')
        this.newCompanyForm()
     else
        return this.existingCompanyForm()
  }

  existingCompanyForm(){
     return(
       <React.Fragment>
           {this.renderLabel('Company Key')}
           {this.renderInput("companyKey", "Company Key")}

           {this.renderLabel('Email')}
           {this.renderInput("email", "Email")}

           {this.renderLabel('Password')}
           {this.renderInput("password", "Password")}

           <div className="btns">
               {this.renderButton("Register", 'register')}               
            </div>
            <p className='create-account'>
               Already have an account?{" "}
               <span
               className='clickable'
               onClick={() => window.location='/login'}
               >
               Login
               </span>
            </p>
       </React.Fragment>
     )
  }
  newCompanyForm=()=>{
     this.props.openRegistration(this.props.data)
  }
  
  render() {
    const {formType} = this.state
    return (
      <div className="register-shopify">
          <h1>Register</h1>
          {formType.length>0 ? 
            <form onSubmit={this.handleSubmit}>
              {this.openForm()}
            </form>
            :
           this.displayOptions()
          } 
      </div>
    );
  }
}

export default ShopifyForm;
