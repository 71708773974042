import http from './httpService'

const apiEndPoint = http.py_app_url + '/home'

let prevReq = []

export async function getAnalyticsData(formData) {
  let call = http.CancelToken.source()
  prevReq.push(call)
  let res = await http.post(apiEndPoint, formData, {
    cancelToken: call.token
  })
  return res
}

export function cancelRequest() {
  if(prevReq.length > 0){
    for(let i=0; i < prevReq.length; i++){
      prevReq[i].cancel("operation canceled.")
    }
    prevReq.pop()
  }
}