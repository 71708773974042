function setComma(value){
  var parts = value.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return parts.join(".")
}

function roundOff(value){
   if(IsNumber(value))
      return parseInt(value)
   return parseFloat(value).toFixed(2)  
}

function IsNumber(n){
  return Number.isInteger(n)
}

export function setNumbers(value){
   if(!value)
      return 0

   return value === 'N/A' ? value : setComma(roundOff(value))
}