import React from 'react';
import Table from '../../../common/Tables/Table';
import TableHeader from '../../../common/Tables/TableHeader';
import TableBody from '../../../common/Tables/TableBody';

function AppTable({columns, sortColumn, onSort, data}) {
  return (
    <Table>
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort}/>
      <TableBody columns={columns} data={data} />
    </Table>
  );
}

export default AppTable;