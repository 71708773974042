import React from 'react';
import { setNumbers } from '../../../common-functions/setNumbers';

const  OrdersList =({currencySymbol, selectedCountry, loading}) => {
  
  const getLoadingTemplate = () => {
      let arr = ['', '', '', '', '', '', '', '', '']
      return(
          <React.Fragment>
              {arr.map((item, key)=>{
                  return(
                    <div className={`d-flex loading justify-content-between`}>
                        <div className="p-2 div">
                        </div>
                        <div className="p-2 div">
                        </div>
                        <div className="p-2 div">
                        </div>
                    </div>
                  )
                })}
          </React.Fragment>
      )
      
  }

  return (
    <div className="row map-data_div">
        <div className="d-flex flex-column">
            <div className="d-flex map-rows header">
                <div className="p-2 map-rows_col1">
                    States
                </div>
                <div className="p-2 map-rows_col2">
                    Orders
                </div>
                <div className="p-2 map-rows_col3">
                    Sales
                </div>
            </div>
            {loading && getLoadingTemplate()}
            
            { !loading && selectedCountry.places.map((item, key) =>{
                return(
                    <div className="d-flex map-rows" key={key}>
                        <div className="p-2 map-rows_col1">
                            {item.place}
                        </div>
                        <div className="p-2 map-rows_col2">
                            {setNumbers(item.orders)}
                        </div>
                        <div className="p-2 map-rows_col3">
                            {currencySymbol}{setNumbers(item.sales)}
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
  );
}

export default OrdersList;