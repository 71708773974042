import React, { useState, useEffect } from "react";

function SearchBox({ searchedValue, searchVariants }) {
  const [searchValue, setSearchValue] = useStickyState("", "search"); // Declare a new state variable
  const search = (e) => {
    searchVariants(e.target.value);
    setSearchValue(e.target.value);
  };
  /* persist state after a page refresh using local storage */
  function useStickyState(defaultValue, key) {
    const [value, setValue] = useState(() => {
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue !== null
        ? searchVariants(JSON.parse(stickyValue))
        : defaultValue;
    });
    useEffect(() => {
      if (value !== undefined) {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    }, [key, value]);
    return [value, setValue];
  }
  /* persist state after a page refresh using local storage end */

  return (
    <div className="search-variant form-group has-search">
      <span className="fa fa-search form-control-feedback"></span>
      <input
        className="form-control searchbar"
        type="text"
        placeholder="Search"
        name="searchedValue"
        value={searchedValue}
        aria-label="Search"
        onChange={search}
      />
    </div>
  );
}

export default SearchBox;
