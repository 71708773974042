import React, { useState, useEffect } from 'react';
import { toastify } from '../../../common-functions/notify';
import { getLayout, startImporting} from '../../../services/mappingPortalService';
import Loading from '../../common/Loading'
import NavigationBar from '../common/NavigationBar';
import CsvPreview from '../common/CsvPreview';
import MapColumns from './Map-Columns/MapColumns';
import ResolverSetting from './ResolverSetting';
import UploadFile from './UploadFile';
import LoadingAnimation from '../../common/LoadingAnimation';

const categories = ['Upload', 'Preview CSV', 'Configure Map Setting', 'Map Columns', 'Finish']

function UpdatePortal({companyKey, csvData, selectedImport, selectedMp, setCsvData}) {
    const [completeTemplateData, setCompleteTemplateData] = useState({})
    const [finalData, setFinalData] = useState({})
    const [selectedCategory, setSelectedCategory] = useState('Upload')
    const [templateData, setTemplateData] = useState(null)
    const [templateTypes, setTemplateTypes] = useState([])
    const [disableImportBtn, setDisableImportBtn] = useState(false)

    const importOptions = [
        {
            label: 'Product Base',
            value:'Product-Base',
            importOption : 'Products',
            mappingStrategy: [{
                label: 'Lyzer Product ID',
                value:'ByID'
                },
                {
                    label: 'Base Sku',
                    value:'baseSku'
                },
                {
                    label: 'Sheet Attributes',
                    value: 'ByAttribute'
                }]
        },
        {
            label: 'Product Variant',
            value:'Product-Variant',
            importOption : 'Products',
            mappingStrategy: [{
                label: 'Lyzer Variant ID',
                value:'ByID'
                },
                {
                    label: 'Variant Sku',
                    value:'BySKU'
                },
                {
                    label: 'Sheet Attributes',
                    value: 'ByAttribute'
                }]
        },
        {
            label: 'Orders',
            value:'Orders',
            importOption : 'Orders',
            mappingStrategy: [{
                label:'Order ID',
                value: 'ByOrderID' 
            },
            {
                label:'Order No.',
                value: 'orderNo' 
            }]
        },
        {
            label: 'Order Items',
            value: 'Order-Items',
            importOption : 'Orders',
            mappingStrategy: [{label: 'Item ID', value: 'ByItemID'}]
        },
        {
            label: 'Customers',
            value: 'Customers',
            importOption : 'Customers',
            mappingStrategy: [{
                label: 'Customer ID',
                value: 'ByCustomerID'
            },
            {
                label: 'Email',
                value: 'ByCustomerEmail'
            },
            {
                label: 'Phone No.',
                value: 'ByMobile'
            }]
        }
    ]

  const getLayoutFn = async () => {
    const formData = {
        'companyKey' : companyKey
    }
    return await getLayout(formData)
  }

  useEffect(() => {
      getLayoutFn().then(res=>{
          setCompleteTemplateData(res)
          let map = new Map()
          for(let [key, value] of Object.entries(res)){
              if(key !== 'Marketplaces')
                  map.set(key,[Object.values(value['fields'])])
          }
          setTemplateData(map)
      })
      if(selectedImport){
          let arr = []
          importOptions.forEach(element => {
              if(element.importOption === selectedImport)
                arr.push(element)
          })
          setTemplateTypes(arr)
      }
      else{
          setTemplateTypes(importOptions)
      }
  }, [])

  const handleNext =()=> {
    if(selectedCategory === 'Configure Map Setting'){
        if(Object.keys(finalData).length === 0)
            toastify('error', "Error", "You probably haven't set any configuration yet. In order to proceed further you must configure the following.")
        else
            setSelectedCategory(categories[categories.indexOf(selectedCategory)+1])
    }
    else{
        setSelectedCategory(categories[categories.indexOf(selectedCategory)+1])
    }
  }

  const handleImport = async ()=> {  
    setDisableImportBtn(true)   
    //   console.log(JSON.stringify(finalData))
    try {
        const response = await startImporting(finalData)
        console.log(response)
        if(response.result === 'success'){
            toastify('success', "Request sent", "Updating will begin soon.")
            window.location='/mappingPortal'
        }   
    } catch (error) {
        
    }
  }

  const setData = (data) => {
      setFinalData(data)
  }

  const renderButton = () => {
    if(selectedCategory === 'Finish' || selectedCategory === 'Upload') 
        return null
    else if(selectedCategory === 'Map Columns')
        return (<button className="portal-btn" onClick={handleImport}>Import</button> )
    return (<button className="portal-btn" onClick={handleNext}>Next</button>)           
  }

  const renderNext = () => {
    if(selectedCategory === 'Upload')
      return (
        <UploadFile
          next={handleNext}
          setCsvData={setCsvData}
          templateTypes={templateTypes}
          templateData={templateData}
        />
      )
    else if(selectedCategory === 'Preview CSV')
      return(<CsvPreview key={companyKey} csvData={csvData}/>)

    else if(selectedCategory === 'Configure Map Setting')
      return(
        <ResolverSetting 
            companyKey={companyKey}
            csvData={csvData}
            importType={templateTypes}
            pimFields={completeTemplateData}
            setData={setData}
        />)
    else if(selectedCategory === 'Map Columns')
        return (
        <MapColumns 
            csvData={csvData}
            finalData={finalData}
            importType={templateTypes}
            pimFields={completeTemplateData}
            setData={setData}
        />)
    else 
      return null
  }

  if(Object.keys(completeTemplateData).length === 0)
    return(<Loading />)
  
  return (
    <div className="container-fluid update-portal">
        {disableImportBtn ? <LoadingAnimation /> : null}
        <div className="row">
          <NavigationBar categories={categories} selectedCategory={selectedCategory}/>
        </div>
        <div className="row">
            {renderButton()}           
        </div>
        <div className="row mt-5 justify-content-center">
          {renderNext()}
       </div>
    </div>
  );
}

export default UpdatePortal;
