import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';

function Dropzone({onFileSelect, onFileDrop}) {
  const inputRef = useRef()
  const [dragging, setDragging] = useState(false)

  useEffect(()=>{
    let div = inputRef.current
    if(div){
        div.addEventListener('dragenter', handleDragIn)
        div.addEventListener('dragleave', handleDragOut)
        div.addEventListener('dragover', handleDrag)
        div.addEventListener('drop', handleDrop)

        return () => {
            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
        }

    }
  })

  const handleDragIn =(e) =>{
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragging(true)
    }
  }

  const handleDragOut = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragging(true)
  }

  const handleDrag =(e)=>{
    e.preventDefault()
    e.stopPropagation()
  }

  const onButtonFileUpload = () => {
    document.getElementById('button-file').addEventListener('click', 
    document.getElementById('file-id').click())
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFileDrop(e.dataTransfer.files[0])
        e.dataTransfer.clearData()
    }
  }

  return (
    <div className="importProducts_uploadDropzone" ref={inputRef}>
        <label className={`importProducts_fileDropzone ${dragging}`}>
            <input type="file" onChange={(event) => onFileSelect(event)} accept=".xlsx, .xls, .csv" id="file-id"/>
            Custom Upload
        </label>
        <div className="utilDiv">
            <Image className="mb-3" src={require('../../assets/fileUpload.png')} thumbnail />
            <br />
            <button className="btn mb-3" onClick={onButtonFileUpload} id="button-file">Choose file to upload</button>
            <br />
            <p className="mb-3">or drop file here</p>
        </div>
    </div>
  );
}

export default Dropzone;