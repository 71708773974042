import React from "react";
export function StockSelect({
  activeFilter,
  openFilter,
  filter,
  closeFilter,
  key1,
  item,
  key2,
  onStockSelect,
}) {
  return (
    <div
      className={`col filter-cols ${
        activeFilter === filter.name ? "active" : null
      }`}
      onMouseOver={openFilter(filter)}
      onMouseLeave={closeFilter}
      key={key1}
    >
      <span>
        {filter.title} <i className="fa fa-angle-down"></i>
      </span>
      <div className="filters-div">
        <ul>
          {filter.value.map((item, key2) => {
            return (
              <li key={key2}>
                <label className="container-radio-sort">
                  {item.name}
                  <input
                    type="radio"
                    name="sorting"
                    onChange={() => onStockSelect(filter.name, key1, key2)}
                    defaultChecked={item.checked}
                  />
                  <span className="checkmark-radio-sort"></span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
