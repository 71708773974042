import React, { useState } from 'react'
import Header from '../common/Header'
import PortalContainer from './PortalContainer'
import HomePage from './HomePage'

function MappingPortal({companyKey}) {
    const [selectedImport, setSelectedImport] = useState()
    const [entryAllowed, setEntryStatus] = useState(false)
    const [csvData, setCsvData] = useState([])
    
    const onImportSelect = (data) => { 
        if(data === 'Create Products'){
            setSelectedImport(data) 
            setEntryStatus(true) 
        }
        else{
            setSelectedImport(data)
            setEntryStatus(true)

        } 
    }

    const renderScreen = ()=> {
        if(entryAllowed)
            return(
                <PortalContainer
                    companyKey={companyKey}
                    csvData={csvData}
                    selectedImport={selectedImport} 
                    setCsvData={(data)=> setCsvData(data)}
                />
            )
        return(
            <HomePage 
                onImportSelect={onImportSelect}
            />
        )
    }
    
    return (
        <div className="container-fluid padding mapping-portal">
            <div className="row mapping-header">                   
                <Header 
                    title='Mapping Portal'
                    secondaryText={''}
                />
            </div>
            <div className="row pl-1 mapping-body">                    
                {renderScreen()}
            </div>
        </div>
    );
}

export default MappingPortal;