import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

class ExportClass extends Component {
  state = {
      data: {},
      error: {},
  }
  onExportSelection = (selectedExport) => { 
    if(selectedExport.includes('Selected')){
      const {data} = this.props
      let temp_arr = []
      temp_arr = data.filter(item => item.checked === true)
      if(temp_arr.length > 0)
          this.setCsvLink(temp_arr, selectedExport)
    }
    else if(selectedExport.includes('Current')){
      const {data} = this.props
      this.setCsvLink(data, selectedExport)
    } 
    else{
      this.setState(prevState =>({
        data : {
           ...prevState.data,
           selectedExport : selectedExport 
        }
      }))
    }  
  }

  setCsvLink = (data, selectedExport) => {
     let finalData = []
     let headers = [...Object.keys(data[0])]
     finalData.push(headers)

     for(let i=0; i < data.length; i++){
        finalData.push(Object.values(data[i]))
     }
     this.setState(prevState =>({
      data : {
         ...prevState.data,
         exportedData : finalData,
         selectedExport : selectedExport
      }
    }))
  }

  handleExport= ()=>{
     this.exportData()
  }
  
  renderRadioButton(label){
     return(
      <label className='container-radio-sort'>
          {label}
          <input
            type='radio'
            name='sorting'
            onChange={()=>this.onExportSelection(label)}
          />
        <span className='checkmark-radio-sort' />
      </label>
     )
  }

  renderExportButton=()=>{
    const {selectedExport, exportedData} = this.state.data
    
    return(
        <div className='modal-buttons mt-3 mb-3'>
           {selectedExport.includes('Selected') || selectedExport.includes('Current') ? 
            <CSVLink data={exportedData}>
                  <Button onClick={this.props.toggleModal}>Export</Button>
            </CSVLink>
           :
           <Button disabled={selectedExport ? false : true} onClick={this.handleExport}>
             Export
            </Button>
          
           }
        </div>
    )
  }
}

export default ExportClass;