import React, { useState, useRef, useEffect } from "react";
import SortProducts from "../../Products/Analytics/Listing/SortProducts";
import { StockSelect } from "./stockSelect";
import { CheckBoxSelect } from "./checkBoxSelect";
import { RadioSelect } from "./radioSelect";
import { RangeSelect } from "./rangeSelect";
import "react-input-range/lib/css/index.css";

function CollectionFilter({
  filters,
  showSort,
  onCheckboxClick: onCheck,
  sortProducts,
  applyRange,
  handleRangeSlide,
  onStockSelect,
  onSalesBased,
  item,
  key2,
}) {
  const node = useRef();
  const [activeFilter, setActiveFilter] = useState("");
  const [rangeArr, setRangeArr] = useState([]);
  const [searchedValue, setSearchValue] = useState("");
  const [filterArr, setFilters] = useState(filters);

  useEffect(() => {
    document.addEventListener("mouseleave", handleClickOutside);
    return () => {
      document.removeEventListener("mouseleave", handleClickOutside);
    };
  }, [filters]);

  const openFilter = (filter) => () => {
    setActiveFilter(filter.name);
  };

  const closeFilter = () => {
    setActiveFilter("");
  };

  const handleClickOutside = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      setActiveFilter("");
    }
  };

  const onCheckboxClick = (filterType, index, index2) => (e) => {
    onCheck(e, filterType, index, index2);
  };

  const onRangeSlide = (index, data) => {
    let arr = [...rangeArr];
    let temp = arr.filter((item) => item.index === index);
    if (temp.length > 0) arr[arr.indexOf(temp[0])].data = { ...data };
    else arr.push({ index: index, data: data });
    setRangeArr([...arr]);
    handleRangeSlide(index, data);
  };

  /* Search Bar in Filter List */
  const search = (event, name) => {
    const { value } = event.target;
    setSearchValue(value);

    const newFilterArr = filters.map((item) => {
      return { ...item };
    });

    var temp = newFilterArr.filter((item) => item.name === name);
    var filtered = temp[0].value.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    newFilterArr[newFilterArr.indexOf(temp[0])].value = filtered;
    //console.log("Temp: " + JSON.stringify(temp[0]));
    setFilters([...newFilterArr]);
  };
  /* Search Bar in Filter List End */

  return (
    <div className="container-fluid lg-filters" ref={node}>
      <div className="row filters-row">
        {showSort && (
          <div
            className={`col filter-cols 
			      ${activeFilter === "sortBy" ? "active" : null}`}
            onMouseOver={openFilter("sortBy")}
            onMouseLeave={closeFilter}
          >
            <span>
              Sort By <i className="fa fa-angle-down"></i>
            </span>
            <div className="filters-div">
              <SortProducts sortProducts={sortProducts} />
            </div>
          </div>
        )}

        {filterArr.map((filter, key) => {
          if (filter.type === "select") {
            return (
              <CheckBoxSelect
                activeFilter={activeFilter}
                openFilter={openFilter}
                filter={filter}
                closeFilter={closeFilter}
                key1={key}
                searchedValue={searchedValue}
                search={search}
                item={item}
                key2={key2}
                onCheckboxClick={onCheckboxClick}
              />
            );
          }
        })}

        {filters.map((filter, key) => {
          if (filter.type === "range") {
            return (
              <RangeSelect
                activeFilter={activeFilter}
                openFilter={openFilter}
                filter={filter}
                closeFilter={closeFilter}
                key1={key}
                item={item}
                key2={key2}
                parseFloat={parseFloat}
                onRangeSlide={onRangeSlide}
                applyRange={applyRange}
                rangeArr={rangeArr}
              />
            );
          }
        })}

        {filters.map((filter, key) => {
          if (filter.type === "hybrid") {
            return (
              <StockSelect
                activeFilter={activeFilter}
                openFilter={openFilter}
                filter={filter}
                closeFilter={closeFilter}
                key1={key}
                item={item}
                key2={key2}
                onStockSelect={onStockSelect}
              />
            );
          }
        })}

        {filters.map((filter, key) => {
          if (filter.type === "radio") {
            return (
              <RadioSelect
                activeFilter={activeFilter}
                openFilter={openFilter}
                filter={filter}
                closeFilter={closeFilter}
                key1={key}
                item={item}
                key2={key2}
                onSalesBased={onSalesBased}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

export default CollectionFilter;
