import http from './httpService'

const apiEndPoint = http.pim_app_url + '/order'
let prevReq = []
let orderReq = []

export async function getFilters(formData) {
  let call = http.CancelToken.source()
  prevReq.push(call)
  let res = await http.post(apiEndPoint + '/getFilters', formData, {
    cancelToken: call.token
  })
  return res
}

export function cancelRequest(){
  while(prevReq.length > 0){
    prevReq.pop().cancel("operation canceled.")   
  }
}

export async function getOrdersList(formData) {
  while(orderReq.length > 0){
    orderReq.pop().cancel("operation canceled.")   
  }
  let call = http.CancelToken.source()
  prevReq.push(call)
  orderReq.push(call)
  let res = await http.post(apiEndPoint + '/listing', formData)
  return res
}

export async function getOrderInfo(formData) {
  let call = http.CancelToken.source()
  prevReq.push(call)
  let res = await http.post(apiEndPoint + '/orderDetails', formData)
  return res
}

export async function getAllOrders(formData) {
  let call = http.CancelToken.source()
  prevReq.push(call)
  let res = await http.post(apiEndPoint + '/orderExport', formData)
  return res
}