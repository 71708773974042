import React from "react";
import { Image } from "react-bootstrap";
export function CheckBoxSelect({
  activeFilter,
  openFilter,
  filter,
  closeFilter,
  key1,
  searchedValue,
  search,
  item,
  key2,
  onCheckboxClick,
}) {
  return (
    <div
      className={`col filter-cols ${
        filter.name === activeFilter ? "active" : null
      }`}
      onMouseOver={openFilter(filter)}
      onMouseLeave={closeFilter}
      key={key1}
    >
      <span>
        {filter.title} <i className="fa fa-angle-down"></i>
      </span>
      <div className="filters-div">
        <ul>
          {/* Search Bar in Filter List */}
          <div className="form-group has-search">
            <span className="fa fa-search form-control-feedback"></span>
            <input
              className="form-control searchbar"
              type="text"
              placeholder="Search"
              name="searchedValue"
              value={searchedValue}
              aria-label="Search"
              onChange={(e) => search(e, filter.name)}
            />
          </div>
          {/* Search Bar in Filter List End */}
          {filter.value.map((item, key2) => {
            return (
              <li key={key2} id="myUL">
                <label className="custom-checkbox">
                  {item.img && (
                    <Image
                      src={require("../../../assets/" + item.img)}
                      loading="lazy"
                      thumbnail
                    />
                  )}
                  {item.name}
                  <input
                    type="checkbox"
                    name={item.name}
                    onChange={onCheckboxClick(filter.name, key1, key2)}
                    checked={item.checked}
                    //style={{ display: item.hide }}
                  />
                  <span className="checkmark"></span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
