import React, {useState, useEffect} from 'react';
import { Bar } from "react-chartjs-2";

function BarGraph(props) {
    const [loading, setLoading] = useState(true)
    const [labels, setLabels] = useState([])
    const [data, setData] = useState([])
    const [colors, setColors] = useState([])

    const {data: obj, colors : propColors} = props

    useEffect(() => {
        setLabels([...obj['labels']])
        setData([...obj['values']])
        setColors([...propColors])       
        setLoading(false)        
    },[obj])

    if( loading === true)
        return <div>loading</div>
    
    else         
        return (
            <div className="bar-graph m-2 pt-2">
                <Bar data={{
                            labels: [...labels],
                            datasets: [{
                                barPercentage: 5.0,
                                barThickness: 10,
                                maxBarThickness: 10,
                                minBarLength: 30,
                                data: [...data],
                                backgroundColor: [...colors],
                            }]                   
                    }}
                    width={100}
                    height={150}
                    options={{ 
                            maintainAspectRatio: false,
                            legend : {
                                display: false,
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display : false,
                                    }
                                }],
                                yAxes: [{
                                    gridLines: {
                                        display : false,
                                    }
                                }]
                            }
                        }}
                />
            </div>
        );
}

export default BarGraph;