import React, { Component } from 'react';
import { getUser } from '../../../../services/authService';
import { cancelRequest, getProductDataPoints, getProductInfo } from '../../../../services/productService';
import ProductList from '../ProductCards/ProductList';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"

class SimilarProducts extends Component {
  constructor(props){
    super(props)
    this.state = {
        similarProductArr : [],
        loading : true,
        loadingDataPoints : true,
        activeLeftBtn : false,
        activeRightBtn : true,
        rightIndex : 3,
        leftIndex : 0
    }
  }

  componentDidMount(){
      if(this.props.products.length > 0)
          this.getPimData()
  }

  getPimData = async () => {
    const {companyKey, products} = this.props
    let {similarProductArr} = this.state
    let temp_arr = [...similarProductArr]

    for(let i=0; i < products.length; i++){
      try{
          const formData = {
            'companyKey' : companyKey,
            'productSku' : products[i],
            'email' : getUser().userEmail,
          }
          let res = await getProductInfo(formData)
          // console.log(res.data)
          if(res.data){
              temp_arr.push({
                  image_url: res.data.product.images[0],
                  baseSku: res.data.product.attributes.baseSku.value,
                  baseKey: res.data.product.attributes.baseKey.value,
                  title: res.data.product.attributes.title.value,
              })
          }                
      }
      catch(ex){
        console.log(ex)
      }
      
    }
    this.setState({
       similarProductArr : temp_arr,
       loading : false
    }, () => this.fetchDataPoints())
  }
  componentWillUnmount(){
    cancelRequest()
}

  fetchDataPoints = async () => {
    const {companyKey, products, toDate, fromDate, companyInfo} = this.props
    try{
      const formData = {
          "toDate": toDate,
          "fromDate": fromDate,
          "companyKey": companyKey,
          "timeZone": companyInfo['Time Zone'],
          "baseSku": [...products],
          "chartTypes": {
              "CARD": [
                  "CARD_TOTAL_SALES",
                  "CARD_AVG_SELLING_PRICE",
                  "CARD_TOTAL_ORDERS",
                  "CARD_PREV_TOTAL_SALES",
              ],
            }   
      }
      // console.log(JSON.stringify(formData))
      let res = await getProductDataPoints(formData)
      // console.log(res.data)
      this.setFinalData({...res.data})
           
    }
    catch(ex){
        console.log(ex)
    }                         
  }

  setFinalData = ( data) => {
    const {similarProductArr} = this.state
    for(let i=0; i < similarProductArr.length; i++){
        if(data[similarProductArr[i].baseSku])
            similarProductArr[i] = {...similarProductArr[i], ...data[similarProductArr[i].baseSku]}
    }
    this.setState({
        similarProductArr,
        loadingDataPoints : false
    })
  }

  moveLeft = () => {
    let {leftIndex, rightIndex} = this.state

    if(leftIndex-1 === 0){
        this.setState({
            leftIndex : leftIndex-1,
            rightIndex : rightIndex-1,
            activeLeftBtn : false,
            activeRightBtn : true
        })
    }
    else{
        this.setState({
          leftIndex : leftIndex-1,
          rightIndex : rightIndex-1,
          activeLeftBtn : true,
          activeRightBtn : true
        })
    }
  }

  moveRight = () => {
      let {rightIndex, leftIndex, similarProductArr} = this.state
      
      if(rightIndex+1 === similarProductArr.length){
          this.setState({
              rightIndex : rightIndex+1,
              leftIndex : leftIndex+1,
              activeRightBtn : false,
              activeLeftBtn : true
          })
      }
      else{
          this.setState({
            rightIndex : rightIndex+1,
            leftIndex : leftIndex+1,
            activeRightBtn : true,
            activeLeftBtn : true
          })
      }
  }

  render() {
    const {loading, loadingDataPoints, similarProductArr, companyInfo, activeLeftBtn, activeRightBtn, leftIndex, rightIndex} = this.state

    return (
      <div className="container-fluid carousel">
          <div className="row carousel-row1">
              <h5>More Products</h5>
          </div>
          <div className="row carousel-row2">
              <div className="left-arrow">
                  {activeLeftBtn && <FontAwesomeIcon
                      className={`left-icon`}
                      icon={faAngleLeft}
                      onClick={this.moveLeft}
                  />}
              </div>
              {!loading && <ProductList
                  products_array ={similarProductArr.slice(leftIndex, rightIndex)}
                  loadingDataPoints={loadingDataPoints}
                  currencySymbol={companyInfo ?companyInfo['Currency Symbol'] : '$'}
                  loadingProducts={loading}
              />}
              <div className="right-arrow">
                  {activeRightBtn &&<FontAwesomeIcon
                      className={`right-icon`}
                      icon={faAngleRight}
                      onClick={this.moveRight}
                  />}
              </div>
          </div>
      </div>
    );
  }
}

export default SimilarProducts;