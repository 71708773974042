import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';

function CheckBox({label, value, img, checked, onCheckboxClick}) {
  useEffect(()=> {

  }, [])
  return (
    <label className="custom-checkbox">
        {label && label}
        {img && <Image src={require('../../assets/'+img)} thumbnail/>}
        <input 
            type="checkbox"
            name={value}
            onChange={(e) =>onCheckboxClick(e, value)}
            checked={checked}
        />
        <span className="checkmark"></span>
    </label>
  );
}

export default CheckBox;