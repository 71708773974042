import axios from "axios";

const pim_app_url = process.env.REACT_APP_PIM_URL;
const py_app_url = process.env.REACT_APP_PYTHON_URL;

let serverError = false

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log(serverError)
  }
  serverError = false
  return Promise.reject(error);
});

function setHeader(token){
  axios.defaults.headers.common['x-auth-token'] = token
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  CancelToken: axios.CancelToken,
  pim_app_url,
  py_app_url,
  serverError,
  setHeader
};
