import React from 'react';

function ProductCard({id, children, product}) {
  return (
    <div className='col-12 col-md-6 col-lg-4 mb-5' key={id}>
        <div className={`product-card-div`}>
          <div className={`product-div ${product.checked ? 'active ' : ''}`}>
              {children}
          </div>
        </div>
    </div>
  );
}

export default ProductCard;