import React, { Component } from 'react';
import { fetchReportData } from '../../../../services/reportService';
import Header from '../../../common/Header';
import { paginate } from '../../../../common-functions/paginate';
import _ from "lodash";
import AppTable from './AppTable';
import Pagination from '../../../common/Pagination';

class ReportContainer extends Component {
  constructor(props){
    super(props)
    this.state={
      columns : [],
      currentPage: 1,
      data : [], 
      loading : true, 
      pageSize : 30,
      sortColumn: {},
      title : '',
    }
  }

  componentDidMount(){
    let report= JSON.parse(sessionStorage.getItem('report'))
    fetchReportData(report.url).then(res => {
       let newData = this.convertCSVToArray(res.data, res.ext)  
       this.setState({data : newData, title : report.processType})
       console.log(newData)      
    })
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.report !== this.props.report){
      let report= JSON.parse(sessionStorage.getItem('report'))
      fetchReportData(report.url).then(res => {
         let newData = this.convertCSVToArray(res.data, res.ext)  
         this.setState({data : newData, title : report.processType, loading : false})
      })
    }
  }

  convertCSVToArray = (res, ext)=> {
    let colSeparator = ''
    let rowSeparator = ''

    if(ext=== 'txt'){
      colSeparator = '\n'
      rowSeparator = '\t'
    }
    else 
    {
      colSeparator = '\n'
      rowSeparator = ','
    }
    const titles = res.slice(0, res.indexOf(colSeparator)).split(rowSeparator)
    this.setState({columns : titles})
    const rows = res.slice(res.indexOf(colSeparator) + 1).split(colSeparator)
    return rows.map(row => {
        const values = row.split(rowSeparator)
        return titles.reduce((object, curr, i) => (object[curr] = values[i], object), {})
    })
  }
  handlePageChange = page => {
    this.setState({ currentPage: page });
  }

  handleSort = sortColumn => {
    this.setState({ sortColumn }, ()=> console.log(sortColumn));
  }

  getPagedData = () => {
    const { pageSize, currentPage, sortColumn, data} = this.state;

    const filtered = [...data]
    let sorted = []
    if(Object.keys(sortColumn).length > 0)
        sorted= _.orderBy(filtered, [sortColumn.path], [sortColumn.order])
    else
      sorted = [...filtered]

    const finalData = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: finalData };
  }
  render() {
    const {title, columns, data, sortColumn, pageSize, currentPage, loading} = this.state

    if (!loading && data.length === 0) return <p>There are no movies in the database.</p>;

    const { totalCount, data: finalData } = this.getPagedData()

    return (
      <div className="container-fluid report-container">
         <Header 
              title='Analytics Report'
              secondaryText={''}
          />
          <div className="row report-body">
              <div className="col-12 col-md-6 col-lg-8">
                  <h5>{title} | {data.length} rows  </h5>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mt-2">
                <Pagination 
                    count={totalCount}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    changePage={this.handlePageChange}
                />
              </div>
          </div>
          <div className="row report-table">
              <AppTable 
                  columns={columns}
                  data={finalData}
                  sortColumn={sortColumn}
                  onSort={this.handleSort}
              />
          </div>
      </div>
    );
  }
}

export default ReportContainer;