import React from 'react';
import { Image } from 'react-bootstrap';
import { getUser } from '../../../services/authService';
import { editNotifications, getAllNotifications } from '../../../services/notificationService';
import { Progress } from 'reactstrap';
import watch from "../../../assets/watch.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import NotificationClass from './NotificationClass';

class Notification extends NotificationClass {
  constructor(props){
     super(props)
     this.state = {
        data : {notifications : [], loading : true, isDisplayed : false, newNotifications : 0, unReadNotifications: 0},
        errors : {},
     }
     this.node = React.createRef()     
  }

  componentDidMount(){
    document.addEventListener("click", this.handleClickOutside)
    this.recieveReatTimeNotifications()
  }

  componentWillUnmount(){
    document.removeEventListener("click", this.handleClickOutside)
    clearInterval(this.recieveReatTimeNotifications())
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.companyKey !== this.props.companyKey){
       this.recieveReatTimeNotifications()
    }
  }

  displayNotifications = ()=>{
    this.setState(prevState=>({
      data : {
        ...prevState.data,
        isDisplayed : !this.state.data.isDisplayed,
        unReadNotifications: 0
      }
    }),
    async () => {
      if(this.state.data.isDisplayed){
        let readNotifications = this.state.data.notifications.reduce((total, item)=>{
            total.push({"type": 'read', "processId": item.id})
            return total
         },[])
        const formData = {
          processes : [...readNotifications]
        }
        const res = await editNotifications(formData)
        this.setState(prevState=>({
          data : {
            ...prevState.data,
            newNotifications : 0,
          }
        }))
      }
    })
  }

  getNotifications = async () => {
    const formData = {
        "companyKey" : this.props.companyKey,
        "email": getUser().userEmail,
        "limit":10
    }
    try{
      const data= await getAllNotifications(formData)
      this.getUnreadNotifications(data)
      // console.log(this.state.data.notifications)

      if(this.state.data.notifications.length < data.length){
        this.setState(prevState=>({
          data : {
             ...prevState.data,
             newNotifications : data.length - this.state.data.notifications.length
          }
        })) 
      }
      this.setState(prevState=>({
        data : {
           ...prevState.data,
           notifications : [...data],
           loading : false,
        }
      }))
    }
    catch(ex){
       console.log(ex)
    }
  }

  getUnreadNotifications = (data)=>{
    let count = 0
    for(let i=0; i < data.length; i++){
      if(data[i].read === false)
        count++
    }
    this.setState(prevState =>({
      data: {
       ...prevState.data,
       unReadNotifications : count
      }
    }))
  }

  recieveReatTimeNotifications = () => {
    let interval = setInterval(() => {
      this.getNotifications()
    }, 3000)
    return interval
  }
  
  handleClickOutside = event => {
    if (this.node.current && !this.node.current.contains(event.target)) {
      this.setState(prevState=>({
        data : {
          ...prevState.data,
          isDisplayed : false,
        }
      }))
    }
  }

  renderListItem = (notification, key) => {
    return(
        <div className={`row notification-list-item ${notification.percent === '100' ? 'active' : ''}`} key={key}>
                <div className="col-3 icon">
                    <img src={this.setImage(notification.processType)} alt='notify-type'/>
                </div>
                <div className="col-9 content">
                    <div className="date-time">
                      <Image src={watch} />
                      {this.setDate(notification.startTime)}
                    </div>
                    <h5>{notification.processType ? notification.processType : 'CSV File'}</h5>
                    <div className="description">
                        {notification.percent === "0" ?
                        <React.Fragment>
                            <p>Preparing</p>
                            <Progress animated value={100} />
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <p>{notification.message}</p>
                          <Progress value={notification.percent} />
                          <span className="perc">{notification.percent}%</span>
                        </React.Fragment>
                        }
                        
                    </div>
                </div>
              <div className="btn-group-div">
                <a href={notification.url} className="btn-group download">Download</a>
                <button className="btn-group view" onClick={()=>this.viewReport(notification)}>View</button>
              </div>
              <FontAwesomeIcon
                    className={`close-icon`}
                    icon={faTimes}
                    onClick={() =>this.deleteItem(notification)}
                />
        </div>
    )
  }

  render() {
    const {notifications, loading, isDisplayed, unReadNotifications} = this.state.data;
    return (
      <div className="notification" ref={this.node}>
        <div className="bell-icon" onClick={this.displayNotifications}>
          <span className={unReadNotifications > 0 ? 'active' : ''}>{unReadNotifications}</span>
          <Image src={require('../../../assets/bell-icon.png')} />
        </div>
        <div className={`container-fluid notification-list ${ isDisplayed ? 'active' : '' }`}>
          <div className="arrow-up"></div>
          {!loading && notifications.length === 0 ?
            <h6 className="zero-notification">You have no notifications</h6> :
            <React.Fragment>
              <h4>Notifications</h4>
              {loading && this.renderLoadingList()}
              {notifications.map((notification, key)=> {
                if(key < 10)
                  return(this.renderListItem(notification, key));
              })}
            </React.Fragment>
          }
          { notifications.length > 10 ?
            <Link to="/notifications">
              <h6 className="view-notification">View All</h6>
            </Link>
          : null}
        </div>
      </div>
    );
  }
}

export default Notification;