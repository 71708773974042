import React from 'react';
import { Link } from 'react-router-dom';
import CheckBox from '../UI Elements/CheckBox';

const TableBody =({ordersArray, onLinkClick, generateData, loading, handleCheckBox}) => {

  const displayLoader = () => {
    let arr = ['', '', '', '', '', '', '', '', '', '']
     return(
       <tbody className={`loading`}>
            {arr.map((item, key)=>{
               return(
                  <tr className="loading" key={key}>
                       <td><div></div></td>
                       <td><div></div></td>
                       <td><div></div></td>
                       <td><div className="div1"></div><div className="div2"></div></td>
                       <td><div></div></td>
                       <td><div></div></td>
                       <td><div></div></td>
                       <td><div></div></td>
                  </tr>
               )
            })}
       </tbody>
     )
  }

  if(loading)
      return displayLoader()
  return (
    <tbody>
      {ordersArray.map((order, key) => {
        return (
          <tr className='pt-4 pb-4' key={key}>
            <td>
                <CheckBox 
                    onCheckboxClick={handleCheckBox}
                    value={key}
                    checked={order.checked}
                />
            </td>
            <td className='order-edit-cell cell orderNumber'>
              <Link
                to={`/orders/${order.orderNo}`}
                params={{ type: order.orderNo }}
                onClick={onLinkClick(order.id)}
                className='order-link'
              >
                {order.orderNo}
              </Link>{" "}
            </td>

            {generateData(order)}
          </tr>
        );
      })}
    </tbody>

  );
}

export default TableBody;