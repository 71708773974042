import React from 'react';
import ExportClass from './ExportClass';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { getUser } from '../../../services/authService';
import { getProductNotifications } from '../../../services/notificationService';
import { toastify } from '../../../common-functions/notify'

class ProductExport extends ExportClass {
  state = {
    data : { 
        exportedData : [],
        selectedExport : '', 
        exportOptions : ['All Products', 'Selected Products', 'Current Page']
    },
    errors : ''
  }

  exportData = async () => {
     const {companyKey, fromDate, toDate, timeZone} = this.props

     const formData = {
        "companyKey":companyKey,
        "email":getUser().userEmail,
        "fromDate": fromDate,
        "toDate": toDate,
        "timeZone":timeZone
     }
     try{
       toastify('success', "Request sent. Downloading will begin soon.")
       this.props.toggleModal()
       let res = await getProductNotifications(formData)
       if(res !== 'success')
            toastify('error', "Error", "Some error occured. Try again later")
     }
     catch(ex){
        console.log(ex)
     }
     
  }
  
  render() {
    const {modalOpen, toggleModal} = this.props
    return (
      <Modal className="export-modal" show={modalOpen} size="md"    aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                Export Products
            </Modal.Title>
            <Button onClick={toggleModal}>Close</Button>
        </Modal.Header>
        <Modal.Body>
          <h5>Export</h5>
          <ul>
            {this.state.data.exportOptions.map((option, key)=> {
              return(
                <li key={key}>
                  {this.renderRadioButton(option)}
                </li>
              );
            })}
          </ul>
          {this.renderExportButton()}
        </Modal.Body>
    </Modal>
    );
  }
}

export default ProductExport;