import React from 'react';
import { Table } from 'reactstrap';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const OrderList =({defaultFields, selectedFields, sortIcon, searchOption, orderStatusOption, ordersArray, onLinkClick, generateData, loading, handleCheckBox}) => {
  return (
    <div className='orders__table p-2'>
        <Table responsive borderless className='order table-borderless'>
           <TableHeader 
              defaultFields={defaultFields}
              selectedFields={selectedFields}
              sortIcon={sortIcon}
              searchOption={searchOption}
           />
           <TableBody
              ordersArray={ordersArray}
              onLinkClick={onLinkClick}
              generateData={generateData}
              loading={loading}
              handleCheckBox={handleCheckBox}
           />
        </Table>
    </div>
  );
}

export default OrderList;