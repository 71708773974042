import React from 'react';
import Router from './components/RouterComponent';
import './scss/App.scss';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
      <Router />
  );
}

export default App;
