import React, { Component } from 'react';

class DropDown extends Component {
    constructor(props){
        
        super(props)
        this.state = {
            dropDown : false,
            email : '',
            username : '',
        }
        this.showDropDown = this.showDropDown.bind(this)
        this.container = React.createRef()
    }
    
    showDropDown(){
        this.setState({
            dropDown : !this.state.dropDown
        })
    }
    changeCompanyKey=(item)=>()=>{
        this.setState({
            dropDown : false
        }, () => this.props.changeCompanyKey(item))
        
    }
    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside)
    }
    componentWillUnmount() {
      document.removeEventListener("click", this.handleClickOutside)
    }
    handleClickOutside=(event) =>{
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({
              dropDown: false,
            })
        }
    }
    render() {
        let {activeCompany, companies} = this.props
        return (
            <div className="header-dropdown" ref={this.container}>
                <span className="company-logo-div" onClick={this.showDropDown} >
                    <span className="p-2 mt-2">{activeCompany} <i className="fa fa-angle-down"></i></span>
                </span>
                
                <div className="company-dropdown" style={{display : this.state.dropDown ? 'block': 'none'}}>
                    {companies.map((item,key)=>{
                        return(
                        <div 
                            key={key} 
                            onClick={this.changeCompanyKey(item)} 
                            className={`p-2 dropdown-options ${activeCompany === item ? 'active' : ''}`}>
                            {item}
                        </div>
                        )
                    })}
                </div>
            </div>
          );
    }
}
 
export default DropDown;
