import React from 'react';

function TableHeader({headers, sortColumn, onSort, columns}) {
  const raiseSort = path => {
    let sortVal = { ...sortColumn }
    if (sortVal.path === path)
      sortVal.order = sortVal.order === "asc" ? "desc" : "asc"
    else {
      sortVal.path = path
      sortVal.order = "asc"
    }
    onSort(sortVal)
  }

  const renderSortIcon = column => {
    if (column !== sortColumn.path) return null
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />
    return <i className="fa fa-sort-desc" />
  }

  return (
    <thead>
        <tr>
          {columns.map(column => (
            <th
              className="clickable"
              key={column}
              onClick={() => raiseSort(column)}
            >
              {column} {renderSortIcon(column)}
            </th>
          ))}
        </tr>
      </thead>
  );
}

export default TableHeader;