import React from "react";
import InputRange from "react-input-range";
export function RangeSelect({
  activeFilter,
  openFilter,
  filter,
  closeFilter,
  key1,
  item,
  key2,
  parseFloat,
  onRangeSlide,
  applyRange,
  rangeArr,
  handleRangeSlide
}) {

    return(
        <div 
            className={`col filter-cols ${activeFilter === 'range' ? 'active' : null}`} 
            onMouseOver={openFilter(filter)}
            onMouseLeave={closeFilter} 
            key={key1}>
			<span>{filter.title} <i className="fa fa-angle-down"></i></span>
			<div className="filters-div">
				<ul>
					{filter.value.map((item, key2) => {
                        return(
                            <li key={key2} className="mt-2 pt-3">
								<h5>{item.label}</h5>
								<InputRange 
                                    maxValue={Number(item.range.max)} 
                                    minValue={Number(item.range.min)} 
                                    value={item.value} 
                                    handleRangeSlide={handleRangeSlide}
                                    onChange={value => onRangeSlide(key2, value)} 
                                />
							</li>
                        );
                    })}
				</ul>
				<button onClick={() => applyRange(rangeArr)}>Apply</button>
			</div>					
		</div>
    );
}  