import http from "./httpService";

const apiEndPoint = http.pim_app_url + "/import";

export async function getLayout(formData) {
   const res = await http.post(apiEndPoint+'/layout', formData)
   return res.data
}

export async function getStores(formData) {
   const res = await http.post(http.pim_app_url+'/integration/getStores', formData)
   return res.data
}

export async function startImporting(formData){
   const res = await http.post(apiEndPoint+'/import', formData)
   return res.data
}

export async function createProducts(formData){
   const res = await http.post(http.pim_app_url+'/register/uploadMapCSV', formData)
   return res.data
}

export function setCsvData(data){
   localStorage.setItem('csvData', data );
}

export function getCsvData(){
   return JSON.parse(localStorage.getItem('csvData'))
}

export function setCsvName(name){
   localStorage.setItem('csvName', name);
}


export function removeAllData(){
   localStorage.removeItem('csvName')
   localStorage.removeItem('csvData')
}

