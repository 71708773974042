import React from "react";
import PropTypes from "prop-types";
import OrderItem from "./OrderItem";
import OrderBilling from "./OrderBilling";

const OrderDetailsMain = ({ items, billing, shipping }) => {
  return (
    <div className='order-main'>
      <div className="container-fluid order-items">
          <div className='row'>
            <div className='col-12 col-sm-3'>
              <p className='order-heading pb-1'>Items Ordered</p>
            </div>
            <div className='col-12 col-sm-2 offset-sm-7'>
              <button className='print-btn mt-1 p-1 pl-2 pr-2 ml-4'>
                <i className='fa fa-print' aria-hidden='true' />
                &nbsp; Print
              </button>
            </div>
          </div>
          {/* Items */}
          <div className="order-header">
              <div className='row order-item__column-headers pt-2'>
                <div className='col-0 col-sm-1 col-md-1'></div>
                <div className='col-3 col-sm-2 col-md-2'>Image</div>
                <div className='col-3 col-sm-2 col-md-2'>SKU</div>
                <div className='col-3 col-sm-2 col-md-2'>Price</div>
                <div className='col-3 col-sm-3 col-md-3'>Title</div>
              </div>
          </div>
          {items.map((item) => (
            <OrderItem item={item} />
          ))}
      </div>
      <OrderBilling billing={billing} shipping={shipping} />
    </div>
  )
}

OrderDetailsMain.propTypes = {
  items: PropTypes.array.isRequired,
  billing: PropTypes.object.isRequired,
  shipping: PropTypes.object.isRequired,
};

export default OrderDetailsMain;
