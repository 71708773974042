import React, { Component } from "react";
import Header from "../../common/Header";
import OrderDetailsLeft from "./OrderDetailsLeft";
import OrderDetailsMain from "./OrderDetailsMain";
import { cancelRequest, getOrderInfo } from "../../../services/orderService";
import moment from "moment";

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: localStorage.getItem("orderId"),
      orderNo: "",
      time: "",
      createdAt: "",
      billing: {
        address: "",
        city: "",
        country: "",
        name: "",
        state: "",
        zip: "",
      },
      customer: {},
      items: [],
      location: "",
      marketPlace: "",
      orderId: "",
      shipping: {
        address: "",
        city: "",
        country: "",
        name: "",
        state: "",
        zip: "",
      },
      status: "",
      totalPrice: "",
      itemCount: "",
      currency: "",
      error : false,
    }
  }
  componentDidMount = async () => {
    let formData = {
      companyKey: this.props.companyKey,
      Id: this.state.id,
    };
    formData = JSON.stringify(formData);
    try {
      const response = await getOrderInfo(formData)
      console.log(response)
      let orders = response.data.orders[0]

      let billing = {
        address: orders.Billing.Address,
        city: orders.Billing.City,
        country: orders.Billing.Country,
        name: orders.Billing.Name,
        state: orders.Billing.State,
        zip: orders.Billing.Zip,
      };

      let customer = {
        id: "",
        name: orders.Customer.Name,
        email: orders.Customer.Email,
        phone: orders.Customer.Phone,
        count: orders.itemCount,
        totalSpent: "",
      };
      let shipping = {
        address: orders.Shipping.Address,
        city: orders.Shipping.City,
        country: orders.Shipping.Country,
        name: orders.Shipping.Name,
        state: orders.Shipping.State,
        zip: orders.Shipping.Zip,
      };
      this.setState({
        billing: billing,
        customer: customer,
        shipping: shipping,
        orderNo: orders.OrderNo,
        items: orders.Items,
        location: orders.Location,
        marketPlace: orders.Marketplace,
        orderId: orders.orderID,
        currency: orders.Currency,
        status: orders.Status,
        totalPrice: orders.TotalPrice,
        itemCount: orders.itemCount,
        createdAt: moment(orders.Date).format("MMM Do, YYYY"),
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
      else if(!ex.message === 'operation cancelled')
        this.setState({ error: true })
    }
  }

  componentWillUnmount(){
    cancelRequest()
  }

  render() {
    const {
      createdAt,
      time,
      status,
      marketPlace,
      location,
      totalPrice,
      currency,
      orderNo,
      customer,
      items,
      billing,
      shipping,
    } = this.state;

    const orderDetails = {
      createdAt,
      time,
      status,
      marketPlace,
      location,
      totalPrice,
      currency,
    };

    return (
      <div
        className='container-fluid pr-2 orders'
        style={{ background: "#fff" }}
      >
        {/* Order page header  */}
        <Header title='Order' secondaryText={orderNo} />
        <div className='row order-detail__section'>
          <div className='col-12 col-sm-3'>
            {/* Left div  */}
            <OrderDetailsLeft orderDetails={orderDetails} customer={customer} />
          </div>
          <div className='col-12 col-sm-9'>
            {/* Main div  */}
            <OrderDetailsMain
              items={items}
              billing={billing}
              shipping={shipping}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OrderDetails;
