import React, { useState, useEffect } from 'react';
import TopCards from './TopCards';

function Cards({ currencySymbol, lineData, loadingDS, data, openGraph}) {
    const colors = ['#e91e63', '#7767c1','#03a9f4', '#ff6054', '#ff9800', '#80e5b8']
    const [card, setCardActive] = useState('')
    const [sortedData, setData] = useState([['', {}], ['', {}], ['', {}], ['', {}], ['', {}]])
 
    useEffect(() => {
        setCardData()     
    }, [loadingDS])
    
    const setCardData = () => {
        if(!loadingDS){
            const d1 = {...data}
            let arr = []
        
            for(let key in d1){
                arr.push([key, data[key]])
            }
            arr = arr.sort(function(a, b) {
                return a[1]['priority'] - b[1]['priority']
            })
            arr = arr.reverse()
            setData(arr)
            setCardActive(arr[0][0])        
        }
    }

    const onCardSelect = (card, graph) => () =>{
        if(graph.graph !== null) {
            setCardActive(card)
            openGraph(graph)
        }
        else
            return('')
    }

    return (
        <div className="container-fluid">
            <div className="row cards d-flex flex-wrap">
                {sortedData.map((item, key) => {
                        if(key < 5)
                            return(
                                <div 
                                    className={`p-2 custom-card ${card === item[0] ? 'active': ''}`} 
                                    id="topcards"
                                    onClick={onCardSelect(item[0], item[1])}
                                    key={key}>
                                    <TopCards 
                                        card={item[1]}
                                        color={colors[key]}
                                        lineData={lineData}
                                        currencySymbol={currencySymbol}
                                        count={key}
                                        loadingDS = {loadingDS}
                                    />
                                </div>
                            )
                    })}
            </div>
        </div>
    );
}

export default Cards;